import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { lastValueFrom } from 'rxjs';
import { CurrencyModel } from '../../share/model/currency.model';
import { ApiService } from '../../share/service/api.service';
import { NotificationShowMessage } from '../notifications/notifications.actions';
import { CurrencyStateModel } from './currency-state.model';
import { LoadCurrencies, LoadProvidedCurrencies } from './currency.actions';

@State<CurrencyStateModel>({
  name: 'currencies',
  defaults: {
    masterLoaded: false,
    providedLoaded: false,
    currencies: [],
    providedCurrencies: [],
    featuredCurrencies: ['USD', 'JPY', 'GBP', 'EUR'],
  },
})
@Injectable()
export class CurrencyState {
  constructor(public api: ApiService, public store: Store) {}

  @Selector([CurrencyState])
  static currencies(state: CurrencyStateModel): CurrencyModel[] {
    return state.currencies;
  }

  @Selector([CurrencyState])
  static providedCurrencies(state: CurrencyStateModel): CurrencyModel[] {
    return state.providedCurrencies;
  }

  @Selector([CurrencyState])
  static featured(state: CurrencyStateModel): string[] {
    return state.featuredCurrencies;
  }

  @Selector([CurrencyState.providedCurrencies, CurrencyState.featured])
  static featuredCurrencies(state: CurrencyModel[], featured: string[]): string[] {
    const available = state.map(item => item.iso4217);
    return featured.filter(item => available.includes(item));
  }

  @Selector([CurrencyState.providedCurrencies, CurrencyState.featured])
  static noneFeaturedCurrencies(state: CurrencyModel[], featured: string[]): string[] {
    const available = state.map(item => item.iso4217);
    return available.filter(item => !featured.includes(item));
  }

  @Action(LoadCurrencies)
  async loadCurrencies(ctx: StateContext<CurrencyStateModel>, { force }: LoadCurrencies): Promise<CurrencyStateModel | void> {
    const { masterLoaded } = ctx.getState();
    if (!masterLoaded || force) {
      try {
        const currencies = await lastValueFrom(this.api.currencies());
        return ctx.patchState({
          masterLoaded: true,
          currencies,
        });
      } catch (e) {
        this.store.dispatch(new NotificationShowMessage('error', 'Failed to load currencies.', e));
      }
    }
  }

  @Action(LoadProvidedCurrencies)
  async loadCurrenciesHavingFxRate(ctx: StateContext<CurrencyStateModel>, { force }: LoadProvidedCurrencies): Promise<CurrencyStateModel | void> {
    const { providedLoaded } = ctx.getState();
    if (!providedLoaded || force) {
      try {
        const providedCurrencies = await lastValueFrom(this.api.providedCurrencies());
        return ctx.patchState({
          providedLoaded: true,
          providedCurrencies,
        });
      } catch (e) {
        this.store.dispatch(new NotificationShowMessage('error', 'Failed to load currencies with existing fx rates.', e));
      }
    }
  }
}
