import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { lastValueFrom } from 'rxjs';
import { ProviderQueryModel } from '../../share/model/provider.model';
import { ApiService } from '../../share/service/api.service';
import { EntityModel } from '../index';
import { entityMapper, getInitialState } from '../utils/entity-mapper';
import { LoadProviderQueries } from './provider-query.action';
import { ProviderQueryStateModel } from './provider-query.model';

@State<ProviderQueryStateModel>({
  name: 'providerQuery',
  defaults: {
    loaded: false,
    providerQueryEntities: getInitialState(),
  },
})
@Injectable()
export class ProviderQueryState {
  constructor(public api: ApiService) {}

  /**
   * Generate node id for provider query
   *
   * @param item Model
   */
  static getRowNodeId(item: ProviderQueryModel): string {
    return `${item.providerQueryId}`;
  }

  /**
   * Get provider queries by price and query type
   *
   * @param queryType Query type
   * @param priceType Price type
   */
  static filteredQueries(queryType: string, priceType: string) {
    return createSelector([ProviderQueryState.providerQueries], (state: ProviderQueryModel[]) => state.filter(item => item.queryType === queryType && item.priceType === priceType));
  }

  /**
   * Get provider queries by query type
   *
   * @param queryType Query type
   */
  static filteredQueriesByQueryType(queryType: string) {
    return createSelector([ProviderQueryState.providerQueries], (state: ProviderQueryModel[]) => state.filter(item => item.queryType === queryType));
  }

  /**
   * Get provider query name by id
   *
   * @param id Provider query id
   */
  static providerQueryNameById(id: number) {
    return createSelector([ProviderQueryState.providerQueryEntities], (state: EntityModel<ProviderQueryModel>) => state.entities[id]?.name || null);
  }

  @Selector([ProviderQueryState])
  static providerQueryEntities(state: ProviderQueryStateModel): EntityModel<ProviderQueryModel> {
    return state.providerQueryEntities;
  }

  @Selector([ProviderQueryState.providerQueryEntities])
  static providerQueries(entities: EntityModel<ProviderQueryModel>): ProviderQueryModel[] {
    return entities.ids.map(id => entities.entities[id]);
  }

  /**
   * Query providers
   *
   * @param ctx State context
   * @param force Payload to force load securities
   */
  @Action(LoadProviderQueries)
  async queryProviders(ctx: StateContext<ProviderQueryStateModel>, { force }: LoadProviderQueries): Promise<void> {
    const { loaded } = ctx.getState();
    if (force || !loaded) {
      const items = await lastValueFrom(this.api.providerQueries());
      const providerQueryEntities = entityMapper(items, ProviderQueryState.getRowNodeId);
      ctx.patchState({
        providerQueryEntities,
        loaded: true,
      });
    }
  }
}
