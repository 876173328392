import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { lastValueFrom, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { RevalidationResult } from '../../share/model/revalidation-result.model';
import { ApiService } from '../../share/service/api.service';
import { EntityModel } from '../index';
import { NotificationShowMessage } from '../notifications/notifications.actions';
import { SelectUser } from '../user/user.actions';
import { entityMapper, getInitialState } from '../utils/entity-mapper';
import { LoadIndexActions, RevalidateVariation, UploadVariationRebalanceFile } from './variation-actions.actions';
import { VariationActionsStateModel, VariationRestatementType } from './variation-actions.model';

const STATE_TOKEN = new StateToken<VariationActionsStateModel>('indexActions');

@State<VariationActionsStateModel>({
  name: STATE_TOKEN,
  defaults: {
    loaded: false,
    latestRevalidationResult: null,
    loadRevalidationResult: false,
    loadRevalidationResultError: false,
    entities: getInitialState(),
    gridState: null,
  },
})
@Injectable()
export class VariationActionsState {
  constructor(private api: ApiService, private store: Store) {}

  /**
   * Unique ID generator
   *
   * @param data Data to generate unique id
   */
  static getRowNodeId(data: VariationRestatementType): string {
    return `${data.indexId}.${data.variationId}.${data.configId}`;
  }

  @Selector([VariationActionsState])
  static loadRevalidationResult(state: VariationActionsStateModel): boolean {
    return state.loadRevalidationResult;
  }

  @Selector([VariationActionsState])
  static loadRevalidationResultError(state: VariationActionsStateModel): boolean {
    return state.loadRevalidationResultError;
  }

  @Selector([VariationActionsState])
  static latestRevalidationResult(state: VariationActionsStateModel): RevalidationResult[] {
    return state.latestRevalidationResult;
  }

  @Selector([VariationActionsState])
  static variationRestatementEntities(state: VariationActionsStateModel): EntityModel<VariationRestatementType> {
    return state.entities;
  }

  @Selector([VariationActionsState.variationRestatementEntities])
  static variationRestatements({ ids, entities }: EntityModel<VariationRestatementType>): VariationRestatementType[] {
    return ids.map(id => entities[id]);
  }

  /**
   * Action handler to load variation restatements
   *
   * @param ctx State context
   * @param force Force load data
   */
  @Action(LoadIndexActions)
  loadData(ctx: StateContext<VariationActionsStateModel>, { force }: LoadIndexActions): Observable<VariationActionsStateModel> {
    const { loaded } = ctx.getState();
    if (force || !loaded) {
      return this.api.getVariationRestatements().pipe(
        map(dtos => {
          const entityArray = dtos.reduce((acc, { name, masterdataId, variations }) => {
            const data = variations.map(item => {
              return {
                indexName: name,
                indexId: masterdataId,
                ...item,
              };
            });
            return [...acc, ...data];
          }, []);
          const entities = entityMapper(entityArray, VariationActionsState.getRowNodeId);
          return ctx.patchState({
            entities,
            loaded: true,
          });
        }),
      );
    }
    return of(ctx.getState());
  }

  @Action(UploadVariationRebalanceFile)
  async uploadRebalanceFile(ctx: StateContext<VariationActionsStateModel>, { indexId, variationId, configId, file }: UploadVariationRebalanceFile): Promise<void> {
    // delete last revalidation result
    ctx.patchState({ latestRevalidationResult: null, loadRevalidationResult: true, loadRevalidationResultError: false });
    try {
      const result = await lastValueFrom(this.api.uploadRebalanceFile(indexId, variationId, configId, file));
      ctx.patchState({
        latestRevalidationResult: result,
      });
    } catch (e) {
      this.store.dispatch(new NotificationShowMessage('error', 'Failed to upload revalidation file.', e));
      ctx.patchState({ loadRevalidationResultError: true });
    } finally {
      ctx.patchState({ loadRevalidationResult: false });
    }
  }

  /**
   * Action to trigger revalidation
   * Validates all rebalances of the given variation.
   *
   * @param ctx State Context
   * @param indexId Index Id
   * @param variationId Variation Id
   */
  @Action(RevalidateVariation)
  revalidateVariation(ctx: StateContext<VariationActionsStateModel>, { indexId, variationId }: RevalidateVariation): Observable<VariationActionsStateModel> {
    // delete last revalidation result
    ctx.patchState({ latestRevalidationResult: null, loadRevalidationResult: true, loadRevalidationResultError: false });
    return this.api.revalidateVariation(indexId, variationId).pipe(
      map(latestRevalidationResult => ctx.patchState({ latestRevalidationResult, loadRevalidationResult: false })),
      catchError(() => of(ctx.patchState({ loadRevalidationResult: false, loadRevalidationResultError: true }))),
    );
  }

  @Action(SelectUser)
  selectedClientChanged(ctx: StateContext<VariationActionsStateModel>): VariationActionsStateModel {
    return ctx.patchState({
      loaded: false,
      loadRevalidationResultError: false,
      latestRevalidationResult: null,
      loadRevalidationResult: false,
      entities: getInitialState(),
    });
  }
}
