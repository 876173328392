import { Component, Input } from '@angular/core';
import dayjs from 'dayjs';
import { timer } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-world-clock',
  template: `
    <span>{{ label }}</span>
    <div class="space"></div>
    <span class="time">{{ time$ | async }}</span>
  `,
  styleUrls: ['./world-clock.component.scss'],
})
export class WorldClockComponent {
  @Input() label;

  @Input() timezone = 'Europe/London';

  @Input() format = 'HH:mm';

  time$ = timer(0, 1000).pipe(map(() => dayjs().tz(this.timezone).format(this.format)));
}
