import { AgGridAngular } from '@ag-grid-community/angular';
import { Directive, forwardRef, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { getValue, Store } from '@ngxs/store';
import { combineLatest, combineLatestWith, debounceTime, filter, map, merge, Subject, takeUntil, throttleTime } from 'rxjs';
import { GridState } from 'src/app/share/model/gridState.model';
import { objectCompare } from 'src/app/share/utils/object-difference';
import { SetGridState } from './grid-state-saver.actions';

@Directive({
  selector: '[appGridStateSaver]',
})
export class GridStateSaverDirective implements OnInit, OnDestroy {
  @Input() statePath: string;

  destroy$ = new Subject<void>();

  constructor(private store: Store, @Inject(forwardRef(() => AgGridAngular)) private agGrid: AgGridAngular) {}

  ngOnInit(): void {
    if (this.statePath) {
      const storeSelect = this.store.select<GridState>(state => getValue(state, this.statePath));
      combineLatest([this.agGrid.gridReady, storeSelect])
        .pipe(
          filter(([options]) => !!options),
          map(params => params[1]),
          throttleTime(500),
          takeUntil(this.destroy$), // complete subscription after destroy
        )
        .subscribe(gridState => {
          this.restoreState(gridState);
        });
      merge(
        this.agGrid.filterChanged,
        this.agGrid.sortChanged,
        this.agGrid.columnResized,
        this.agGrid.columnPinned,
        this.agGrid.columnMoved,
        this.agGrid.columnRowGroupChanged,
        this.agGrid.columnVisible,
        this.agGrid.columnValueChanged,
        this.agGrid.columnPivotChanged,
        this.agGrid.columnPivotModeChanged,
      )
        .pipe(
          combineLatestWith(storeSelect),
          map(params => params[1]),
          debounceTime(250),
          takeUntil(this.destroy$),
        )
        .subscribe(gridState => this.saveState(gridState));
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  saveState(gridState: GridState): void {
    const newGridState: GridState = {
      filter: this.agGrid.api.getFilterModel(),
      column: this.agGrid.columnApi.getColumnState(),
      columnGroup: this.agGrid.columnApi.getColumnGroupState(),
      pivotMode: this.agGrid.columnApi.isPivotMode(),
    };

    if (!objectCompare(gridState, newGridState)) {
      this.store.dispatch(new SetGridState(this.statePath, newGridState));
    }
  }

  restoreState(gridState: GridState): void {
    if (gridState) {
      this.agGrid.api.setFilterModel(gridState.filter || {});
      this.agGrid.columnApi.applyColumnState({ state: Array.isArray(gridState.column) ? gridState.column : [], applyOrder: true });
      this.agGrid.columnApi.setColumnGroupState(Array.isArray(gridState.columnGroup) ? gridState.columnGroup : []);
      this.agGrid.columnApi.setPivotMode(gridState.pivotMode || false);
    } else {
      this.agGrid.api.setFilterModel(null);
      this.agGrid.columnApi.resetColumnState();
      this.agGrid.columnApi.resetColumnGroupState();
      this.agGrid.columnApi.setPivotMode(false);
    }
  }
}
