/**
 * Restatement action to load data
 */
export class LoadIndexActions {
  static readonly type = '[Variation-Action] load data';

  constructor(public force: boolean = false) {}
}

export class UploadVariationRebalanceFile {
  static readonly type = '[Variation-Action] upload rebalance file';

  constructor(public indexId: number, public variationId: number, public configId: number, public file: File) {}
}

/**
 * Revalidation action
 */
export class RevalidateVariation {
  static readonly type = '[Variation-Action] revalidate variation';

  constructor(public indexId: number, public variationId: number) {}
}
