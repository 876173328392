import { Store } from '@ngxs/store';
import { OperatorFunction, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationShowMessage } from './notifications.actions';

/**
 * Wrap rxjs operator to dispatch error notifications
 *
 * @param store Data store
 * @param message Message to dispatch
 */
export const rxCatchError = <T>(store: Store, message: string): OperatorFunction<T, T> =>
  catchError((e: unknown) => {
    wrapError(store, message, e as Error);
    return throwError(e);
  });

/**
 * Wrap error message
 *
 * @param store Data store
 * @param message Message to dispatch
 * @param e Original error message
 */
export const wrapError = (store: Store, message: string, e: Error): void => {
  store.dispatch(new NotificationShowMessage('error', message, e));
};

/**
 * Wrap success message
 *
 * @param store
 * @param message
 */
export const wrapSuccess = (store: Store, message: string): void => {
  store.dispatch(new NotificationShowMessage('success', message));
};
