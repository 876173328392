// Angular base modules
// External modules
import { AgGridModule } from '@ag-grid-community/angular';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { SideBarModule } from '@ag-grid-enterprise/side-bar';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { ViewportRowModelModule } from '@ag-grid-enterprise/viewport-row-model';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule, MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule, MAT_TABS_CONFIG } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxsGridStateSaverPluginModule } from 'src/app/core/grid-state-saver/grid-state-saver.module';
import { TranslateModule } from '@ngx-translate/core';

// Components
import { PermissionControlDirective } from '../../core/permission-control/permission-control.directive';
import { PermissionControlPipe } from '../../core/permission-control/permission-control.pipe';
import { DayjsAdapterModule } from '../dayjs-adapter/dayjs-adapter.module';
import { MAT_DAYJS_DATE_FORMATS } from '../dayjs-adapter/dayjs-date.formats';
import { FillPipe } from '../pipes/fill.pipe';
import { FnPipe } from '../pipes/fn.pipe';
import { NameNormalizerPipe } from '../pipes/name-normalizer.pipe';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { DateRangeModalComponent } from './date-range-modal/date-range-modal.component';
import { DisableControlDirective } from './disable-control/disable-control.directive';
import { LetDirective } from './let-directive/let.directive';
import { LoadingIndicatorComponent } from './loading-indicator/loading-indicator.component';
import { PublishStatusCircleIconsComponent } from './publish-status-circle-icons/publish-status-circle-icons.component';
import { PublishStatusTooltipComponent } from './publish-status-tooltip/publish-status-tooltip.component';
import { TableCellButtonComponent } from './table-cell-button/table-cell-button.component';
import { TableCellCheckboxComponent } from './table-cell-checkbox/table-cell-checkbox.component';
import { TableCellIconComponent } from './table-cell-icon/table-cell-icon.component';
import { TableCellValueDiffComponent } from './table-cell-value-diff/table-cell-value-diff.component';
import { TableFilterButtonComponent } from './table-filter-buttons/table-filter-button.component';
import { AgToolPanelSelectedItemsComponent } from './table-toolpanel-selected-items/ag-tool-panel-selected-items.component';
import { WorldClockComponent } from './world-clock/world-clock.component';
import { StopScrollDirective } from './disable-scroll-number-input-field-directive/disable-scroll-number-input-field.directive';

export const materialModules = [
  DayjsAdapterModule,
  MatAutocompleteModule,
  MatBadgeModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatIconModule,
  MatInputModule,
  MatFormFieldModule,
  MatListModule,
  MatMenuModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatSelectModule,
  MatSnackBarModule,
  MatStepperModule,
  MatSortModule,
  MatTooltipModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  OverlayModule,
];

// AG Grid module registry
ModuleRegistry.registerModules([
  ClipboardModule,
  ClientSideRowModelModule,
  ColumnsToolPanelModule,
  ExcelExportModule,
  FiltersToolPanelModule,
  MenuModule,
  RangeSelectionModule,
  RichSelectModule,
  RowGroupingModule,
  SetFilterModule,
  MultiFilterModule,
  SideBarModule,
  StatusBarModule,
  ViewportRowModelModule,
]);

@NgModule({
  declarations: [
    AgToolPanelSelectedItemsComponent,
    ConfirmModalComponent,
    DateRangeModalComponent,
    DisableControlDirective,
    PermissionControlDirective,
    NameNormalizerPipe,
    LetDirective,
    StopScrollDirective,
    LoadingIndicatorComponent,
    FillPipe,
    FnPipe,
    PermissionControlPipe,
    PublishStatusCircleIconsComponent,
    PublishStatusTooltipComponent,
    TableCellButtonComponent,
    TableCellCheckboxComponent,
    TableCellIconComponent,
    TableCellValueDiffComponent,
    TableFilterButtonComponent,
    DisableControlDirective,
    WorldClockComponent,
  ],
  imports: [AgGridModule, NgxsGridStateSaverPluginModule.forRoot(), FormsModule, ReactiveFormsModule, CommonModule, NgxDropzoneModule, ...materialModules],
  exports: [
    AgGridModule,
    ConfirmModalComponent,
    DateRangeModalComponent,
    DisableControlDirective,
    LetDirective,
    StopScrollDirective,
    PermissionControlDirective,
    NgxsGridStateSaverPluginModule,
    FnPipe,
    PermissionControlPipe,
    LoadingIndicatorComponent,
    NgxDropzoneModule,
    PublishStatusCircleIconsComponent,
    PublishStatusTooltipComponent,
    TableCellButtonComponent,
    TableCellCheckboxComponent,
    TableCellIconComponent,
    TableCellValueDiffComponent,
    TableFilterButtonComponent,
    TranslateModule,
    WorldClockComponent,
    ...materialModules,
  ],
  providers: [
    { provide: MAT_TABS_CONFIG, useValue: { animationDuration: 0 } },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'auto', appearance: 'fill' } },
    { provide: MAT_DATE_FORMATS, useValue: MAT_DAYJS_DATE_FORMATS },
    { provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: { color: 'primary' } },
    { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { color: 'primary' } },
  ],
})
export class SharedModule {}
