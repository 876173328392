import { Inject, Injectable, Optional } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, map, Observable } from 'rxjs';

@Injectable()
export class ComponentStoreService<T> {
  constructor(@Inject('initialState') @Optional() private initialState: T) {
    this.state$ = new BehaviorSubject<T>(initialState);
  }

  private state$: BehaviorSubject<T>;

  public getState(): Observable<T> {
    return this.state$.asObservable();
  }

  /**
   * Get initial state
   */
  public getInitialState(): T {
    return { ...this.initialState };
  }

  /**
   * Get current state snapshot
   */
  public getSnapshot(): T {
    return this.state$.getValue();
  }

  /**
   * Patch state with new state values
   *
   * @param newState New partial state values
   */
  public setState(newState: Partial<T>): void {
    const oldState = this.state$.getValue();
    this.state$.next({
      ...oldState,
      ...newState,
    });
  }

  /**
   * Selects a slice of data from the store.
   *
   * @param selector Select field in state
   */
  public select<K>(selector: (state: T) => K): Observable<K> {
    return this.state$.asObservable().pipe(
      map(state => selector(state)),
      distinctUntilChanged(),
    );
  }
}
