/**
 * Action to load currencies
 */
export class LoadCurrencies {
  static readonly type = '[Currency] currencies';

  constructor(public force = false) {}
}

/**
 * Action to load currencies which have a fx rate
 */
export class LoadProvidedCurrencies {
  static readonly type = '[Currency] load currencies having fx rate ';

  constructor(public force = false) {}
}
