import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import Auth0Lock from 'auth0-lock';
import { interval } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { LoadPermissionMap, Login } from '../../state/user/user.actions';
import { Client } from '../../state/user/user.model';
import { UserState } from '../../state/user/user.state';

export interface AuthOptions {
  domain: string;
  clientID: string;
  options: Auth0LockConstructorOptions;
  loginRedirect?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private lock = new Auth0Lock(this.options.clientID, this.options.domain, this.options.options);

  sessionCheck$ = interval(10 * 60 * 1000).pipe(
    filter(() => this.store.selectSnapshot(UserState.isAuthenticated)),
    tap(() => this.lock.checkSession({}, this.handleCallback)),
  );

  constructor(private store: Store, @Inject('AuthOptions') private options: AuthOptions) {
    this.handleCallback = this.handleCallback.bind(this);
    this.lock.on('authenticated', authResult => {
      this.handleCallback(null, authResult);
    });
    this.lock.on('authorization_error', error => {
      this.handleCallback(error, null);
    });
    this.lock.checkSession({}, this.handleCallback);
    this.sessionCheck$.subscribe();
  }

  public showLogin(): void {
    this.lock.show();
  }

  public hideLogin(): void {
    this.lock.hide();
  }

  public logout(): void {
    this.lock.logout({
      returnTo: `${window.location.origin}`,
    });
  }

  handleError(err): void {
    console.log(err);
  }

  private handleCallback(err, authResult: AuthResult): void {
    if (err || !authResult.accessToken) {
      return this.handleError(err);
    } else {
      return this.handleAuthentication(true, authResult);
    }
  }

  private handleAuthentication(authenticated: boolean, auth: AuthResult): void {
    if (authenticated) {
      const token = auth.accessToken;
      const clients = (auth.idTokenPayload['https://indexengine.com/clients'] as Client[]) || [];
      const userName = (auth.idTokenPayload['name'] as string) || '';
      this.store.dispatch(new Login({ token, clients, userName }));
      this.store.dispatch(new LoadPermissionMap());
    }
  }
}
