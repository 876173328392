import { ILoadingOverlayAngularComp } from '@ag-grid-community/angular';
import { ILoadingOverlayParams } from '@ag-grid-community/core';
import { Component, Input } from '@angular/core';
import { getValue, Store } from '@ngxs/store';
import { BehaviorSubject, combineLatestWith, iif, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss'],
})
export class LoadingIndicatorComponent implements ILoadingOverlayAngularComp {
  public params!: ILoadingOverlayParams & { loading: boolean };

  agInit(params: ILoadingOverlayParams & { loading: boolean }): void {
    this.loadingLocal$.next(params.loading);
  }

  @Input() set storePath(storePath: string) {
    this.loadingStorePath$.next(storePath);
  }

  @Input() set loading(loading: boolean) {
    this.loadingLocal$.next(loading);
  }

  @Input() asEmbedded = false;

  @Input() bars = 4;

  loadingStorePath$ = new BehaviorSubject<string>(null);

  loadingLocal$ = new BehaviorSubject<boolean>(false);

  loading$: Observable<boolean> = this.loadingLocal$.pipe(
    combineLatestWith(
      this.loadingStorePath$.pipe(
        switchMap(path =>
          iif(
            () => !!path,
            this.store.select<boolean>(state => getValue(state, path)),
            of(false),
          ),
        ),
      ),
    ),
    map(([val1, val2]) => val1 || val2),
  );

  constructor(private store: Store) {}
}
