import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { SecurityModel, SecurityType } from '../../share/model/security.model';
import { LoadSecurityType } from '../../state/securities/securities.actions';
import { SecuritiesState } from '../../state/securities/securities.state';

export interface EditSecurityDataModel {
  edit: string;
  securityId: number | string;
}

@Component({
  selector: 'app-edit-security-modal',
  templateUrl: './edit-security-modal.component.html',
  styleUrls: ['./edit-security-modal.component.scss'],
})
export class EditSecurityModalComponent implements OnInit {
  @Select(SecuritiesState.securityType) securityTypes$!: Observable<SecurityType[]>;

  editMode$ = new BehaviorSubject<boolean>(false);

  selectedSecurityType$ = new BehaviorSubject<string>(null);

  securityIdChanged$ = new BehaviorSubject<string | number>(null);

  security$ = this.securityIdChanged$.pipe(
    filter(id => !!id),
    map(id => this.store.selectSnapshot(SecuritiesState.getSecurityById(id))),
    tap(data => this.selectedSecurityType$.next(data.assetClass)),
  );

  constructor(public store: Store, public dialogRef: MatDialogRef<EditSecurityModalComponent>, @Inject(MAT_DIALOG_DATA) public data: EditSecurityDataModel) {}

  ngOnInit(): void {
    this.editMode$.next(!!this.data?.securityId);
    this.securityIdChanged$.next(this.data?.securityId);
    this.store.dispatch(new LoadSecurityType());
  }

  /**
   * Triggering security type selection
   *
   * @param evt selected type
   */
  selectType(evt: MatSelectChange) {
    const types = this.store.selectSnapshot(SecuritiesState.securityType);
    const selectedType = types.find(item => item.name === evt.value);
    this.selectedSecurityType$.next(selectedType?.assetClass);
  }

  /**
   * Submit form values to parent
   *
   * @param values modal entries
   */
  dispatchCreatOrEdit(values: SecurityModel): void {
    const assetClass = this.selectedSecurityType$.value;
    const securityId = this.securityIdChanged$.value;
    this.dialogRef.close({ ...values, assetClass, securityId, cusip: '' });
  }
}
