import { GridState } from 'src/app/share/model/gridState.model';

export class ResetGridState {
  static readonly type = '[Grid State] Reset state';

  constructor(public statePath: string) {}
}

export class SetGridState {
  static readonly type = '[Grid State] Set state';

  constructor(public statePath: string, public payload: GridState) {}
}
