import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';

import { UserState } from '../../state/user/user.state';
import { AuthOptions } from '../service/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(@Inject('AuthOptions') private authOptions: AuthOptions, private router: Router, private store: Store) {}

  async canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const authenticated = this.store.selectSnapshot(UserState.isAuthenticated);
    if (!authenticated) {
      await this.router.navigate([this.authOptions.loginRedirect], { queryParams: { url: state.url } });
    }
    return authenticated;
  }
}
