<div class="content-wrapper">
  <mat-card class="content">
    <ag-grid-angular [columnDefs]="columnDefs" [defaultColDef]="gridColumnDefaults"
                     [getContextMenuItems]="contextMenuItems" [gridOptions]="gridOptions"
                     [rowData]="securities$ | async"
                     [sideBar]="gridSidebarDefs" class="ag-theme-engine"
                     appGridStateSaver statePath="securities.gridState">
    </ag-grid-angular>
    <div class="button-row">
      <div class="mat-spacer"></div>
      <button [permissionModule]="'securities'" [permissionScope]="'WRITE'" appPermissionControl
              (click)="addOrEditSecurityModal(null)" class="buttons" mat-stroked-button>
        Create
      </button>
    </div>
  </mat-card>
</div>
