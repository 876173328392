import { ModuleWithProviders, NgModule } from '@angular/core';
import { NGXS_PLUGINS } from '@ngxs/store';
import { GridStateSaverDirective } from './grid-state-saver.directive';
import { GridStateSaverModulePlugin } from './grid-state-saver.plugin';

@NgModule({
  declarations: [GridStateSaverDirective],
  exports: [GridStateSaverDirective],
})
export class NgxsGridStateSaverPluginModule {
  static forRoot(): ModuleWithProviders<NgxsGridStateSaverPluginModule> {
    return {
      ngModule: NgxsGridStateSaverPluginModule,
      providers: [
        {
          provide: NGXS_PLUGINS,
          useClass: GridStateSaverModulePlugin,
          multi: true,
        },
      ],
    };
  }
}
