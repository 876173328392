import { SecurityModel } from '../../share/model/security.model';

/**
 * Trigger action to load securities
 */
export class SecuritiesLoad {
  static readonly type = '[Securities] load securities';

  /**
   * Force load securities
   *
   * @param force Indicator
   */
  constructor(public force: boolean = false) {}
}

export class LoadSecurityType {
  static readonly type = '[Securities] load security types';

  constructor(public force: boolean = false) {}
}

export class DispatchAddSecurity {
  static readonly type = '[Securities] add new security';

  constructor(public payload: SecurityModel) {}
}

export class DispatchEditSecurity {
  static readonly type = '[Securities] saving edited security';

  constructor(public payload: SecurityModel) {}
}

export class DispatchDeleteSecurity {
  static readonly type = '[Securities] delete security';

  constructor(public payload: string) {}
}
