import { IndexActionModel } from 'src/app/share/model/index-action.model';

/**
 * Action to handle select/deselect index action on grid
 */
export class SelectActions {
  static readonly type = '[Index-Actions] (De)Select corporate action index actions';

  constructor(public payload: string[]) {}
}

/**
 * Action to delete selected index actions
 */
export class DeleteSelectedActions {
  static readonly type = '[Index-Actions] Delete selected corporate action index actions';
}

/**
 * Update or create index-action
 */
export class UpdateOrCreateAction {
  static readonly type = '[Index-Actions] Update or create index-action';

  constructor(public payload: IndexActionModel, public create = false) {}
}
