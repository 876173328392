import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { lastValueFrom } from 'rxjs';
import { ApiService } from 'src/app/share/service/api.service';
import { NotificationShowMessage } from '../notifications/notifications.actions';
import { LoadMetaValues, LoadTimezones } from './meta.actions';
import { MetaStateModel } from './meta.model';

@State<MetaStateModel>({
  name: 'meta',
  defaults: {
    loaded: false,
    timezones: [],
  },
})
@Injectable()
export class MetaState {
  constructor(private api: ApiService, private store: Store) {}

  @Selector([MetaState])
  static timezones(state: MetaStateModel): string[] {
    return state.timezones;
  }

  @Action(LoadMetaValues)
  async loadMetaValues(ctx: StateContext<MetaStateModel>, { force }: LoadMetaValues): Promise<void> {
    const { loaded } = ctx.getState();
    if (!loaded || force) {
      await lastValueFrom(this.store.dispatch(new LoadTimezones()));
      ctx.patchState({ loaded: true });
    }
  }

  @Action(LoadTimezones)
  async loadTimezones(ctx: StateContext<MetaStateModel>): Promise<void> {
    try {
      const timezones = await lastValueFrom(this.api.getTimezones());
      ctx.patchState({ timezones });
    } catch (e) {
      this.store.dispatch(new NotificationShowMessage('error', 'Failed to load timezones.', e));
    }
  }
}
