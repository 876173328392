import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { NotifierService } from 'angular-notifier';
import produce from 'immer';
import { EntityModel } from '../index';
import { getInitialState } from '../utils/entity-mapper';
import { NotificationLevelChanged, NotificationShowMessage, NotificationUnreadReset } from './notifications.actions';
import { MessageLevel, MessageType, Notification, NotificationsStateModel } from './notifications.model';

@State<NotificationsStateModel>({
  name: 'notifications',
  defaults: {
    level: 'all',
    entities: getInitialState(),
    unread: 0,
  },
})
@Injectable()
export class NotificationsState {
  constructor(private notifier: NotifierService) {}

  @Selector([NotificationsState])
  static unreadCount(state: NotificationsStateModel): number {
    return state.unread;
  }

  @Selector([NotificationsState])
  static level(state: NotificationsStateModel): MessageLevel {
    return state.level;
  }

  @Selector([NotificationsState])
  static notificationEntities(state: NotificationsStateModel): EntityModel<Notification> {
    return state.entities;
  }

  @Selector([NotificationsState.notificationEntities, NotificationsState.level])
  static notifications(state: EntityModel<Notification>, level: MessageLevel): Notification[] {
    if (level === 'all') {
      return state.ids.map(id => state.entities[id]);
    } else {
      return state.ids.map(id => state.entities[id]).filter(item => item.level === level);
    }
  }

  @Action(NotificationShowMessage)
  showAndStoreNotification(ctx: StateContext<NotificationsStateModel>, payload: NotificationShowMessage): NotificationsStateModel {
    const { entities, unread } = ctx.getState();
    const { date, level, message, error } = payload;
    if (error && error.name === 'EmptyError' && !('status' in error)) {
      return null;
    }
    const newEntities = produce(entities, draft => {
      const id = String(entities.ids.length);
      draft.ids.unshift(id);
      draft.entities[id] = { date, level, message, error };
    });
    this.dispatchNotification(level, message);
    return ctx.patchState({
      entities: newEntities,
      unread: unread + 1,
    });
  }

  @Action(NotificationUnreadReset)
  resetNotificationUnreadCounter(ctx: StateContext<NotificationsStateModel>): NotificationsStateModel {
    return ctx.patchState({
      unread: 0,
    });
  }

  @Action(NotificationLevelChanged)
  changeNotificationLevel(ctx: StateContext<NotificationsStateModel>, { level }: NotificationLevelChanged): NotificationsStateModel {
    return ctx.patchState({
      level,
    });
  }

  /**
   * Show notification message
   *
   * @param type Notification level
   * @param message Message to display
   */
  dispatchNotification(type: MessageType, message: string): void {
    this.notifier.show({
      type,
      message,
    });
  }
}
