<form #form="ngForm" (ngSubmit)="dispatchSaveConfig(form.value)">
  <h1 mat-dialog-title>{{data.edit ? 'Edit' : 'Create'}} Index Configuration</h1>
  <mat-dialog-content *ngLet="selectedConfig$ | async as config" class="content-wrapper">
    <mat-form-field *ngIf="data.edit">
      <mat-label>Config ID</mat-label>
      <input [disabled]="data.edit" [ngModel]="config?.configId" matInput name="configId"/>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Index</mat-label>
      <mat-select [disabled]="data.edit" [ngModel]="config?.masterdataId" name="masterdataId" required>
        <mat-option *ngFor="let index of indices$ | async" [value]="index.masterdataId">
          {{index.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Name</mat-label>
      <input [ngModel]="config?.name" matInput name="name" required/>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Currency</mat-label>
      <mat-select [ngModel]="config?.currency" name="currency" required>
        <mat-option *ngFor="let currency of currencies$ | async"
                    [value]="currency.iso4217">{{currency.iso4217}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Close Prices</mat-label>
      <mat-select [ngModel]="config?.defaultProviders.closePrices || 0" name="closePrices">
        <mat-option [value]="0">Not Selected</mat-option>
        <mat-option *ngFor="let query of closePriceQueries$ | async" [value]="query.providerId">
          {{query.providerName}} - {{query.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Real-Time Prices</mat-label>
      <mat-select [ngModel]="config?.defaultProviders.realtimePrices || 0" name="realtimePrices">
        <mat-option [value]="0">Not Selected</mat-option>
        <mat-option *ngFor="let query of realtimePriceQueries$ | async" [value]="query.providerId">
          {{query.providerName}} - {{query.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Close FX</mat-label>
      <mat-select [ngModel]="config?.defaultProviders.closeFX || 0" name="closeFX">
        <mat-option [value]="0">Not Selected</mat-option>
        <mat-option *ngFor="let query of closeFxQueries$ | async" [value]="query.providerId">
          {{query.providerName}} - {{query.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Real-Time FX</mat-label>
      <mat-select [ngModel]="config?.defaultProviders.realtimeFX || 0" name="realtimeFX">
        <mat-option [value]="0">Not Selected</mat-option>
        <mat-option *ngFor="let query of realtimeFxQueries$ | async" [value]="query.providerId">
          {{query.providerName}} - {{query.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <table *ngIf="config?.applicableIndices.length > 0" [dataSource]="config?.applicableIndices || []" mat-table>
      <ng-container matColumnDef="variationId">
        <th *matHeaderCellDef mat-header-cell>ID</th>
        <td *matCellDef="let element" mat-cell>{{element.variationId}}</td>
      </ng-container>
      <ng-container matColumnDef="currency">
        <th *matHeaderCellDef mat-header-cell>Currency</th>
        <td *matCellDef="let element" mat-cell>{{element.currency}}</td>
      </ng-container>
      <ng-container matColumnDef="variationName">
        <th *matHeaderCellDef mat-header-cell>Name</th>
        <td *matCellDef="let element" mat-cell>{{element.variationName}}</td>
      </ng-container>
      <tr *matHeaderRowDef="columnsToDisplay" mat-header-row></tr>
      <tr *matRowDef="let row, columns: columnsToDisplay" mat-row></tr>
    </table>
  </mat-dialog-content>
  <div class="modal-buttons" mat-dialog-actions>
    <button [mat-dialog-close]="null" mat-button>Cancel</button>
    <button [disabled]="form.invalid" color="primary" mat-button type="submit">Save</button>
  </div>
</form>
