import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ClipboardService } from 'ngx-clipboard';
import { Client } from '../user/user.model';
import { UserState } from '../user/user.state';
import { PermissionMap } from 'src/app/share/model/permission.model';
import { CopyToClipboard, DeviceType, SelectClockItem, ToggleLockNavbar, ToggleNavbarOpen } from './global.actions';
import { ClockItem, GlobalStateModel, NavItem } from './global.model';

@State<GlobalStateModel>({
  name: 'global',
  defaults: {
    sideNavLocked: false,
    sideNavOpen: true,
    sideNavMode: 'side',
    deviceType: 'desktop',
    selectedWorldClock: null,
    worldClockItems: [
      {
        name: 'London',
        timezone: 'Europe/London',
      },
      {
        name: 'New York',
        timezone: 'America/New_York',
      },
      {
        name: 'Berlin',
        timezone: 'Europe/Berlin',
      },
      {
        name: 'Tokyo',
        timezone: 'Asia/Tokyo',
      },
    ],
    sideNavItems: [
      {
        icon: 'desktop_mac',
        title: 'Monitoring',
        path: ['monitoring'],
        module: 'index_calculation',
      },
      {
        icon: 'data_exploration',
        title: 'Manage Indices',
        path: ['manage-indices'],
        module: 'manage_indices',
      },
      {
        icon: 'commit',
        title: 'Statement Executor',
        path: ['executor'],
        module: 'sql_executor',
      },
      {
        icon: 'build_circle',
        title: 'Index Launcher',
        path: ['index-launcher'],
        module: 'index',
      },
      {
        icon: 'check_circle',
        title: 'Corporate Actions',
        path: ['corporate-actions'],
        module: 'corporate_actions',
      },
      {
        icon: 'fact_check',
        title: 'Securities',
        path: ['securities'],
        module: 'securities',
      },
      {
        icon: 'price_check',
        title: 'Prices',
        path: ['prices'],
        module: 'securities',
      },
      {
        icon: 'dataset',
        title: 'Country Characteristics',
        path: ['country-characteristics'],
        module: 'input_data',
      },
      {
        icon: 'today',
        title: 'Calendars',
        path: ['calendar'],
        module: 'calendar',
      },
      {
        icon: 'currency_exchange',
        title: 'Realtime-Distribution',
        path: ['realtime-distribution'],
        module: 'distribution',
      },
    ],
  },
})
@Injectable()
export class GlobalState {
  constructor(private clipboard: ClipboardService) {}

  @Selector([GlobalState])
  static clockItems(state: GlobalStateModel): ClockItem[] {
    return state.worldClockItems;
  }

  @Selector([GlobalState])
  static selectedClockItemId(state: GlobalStateModel): string | null {
    return state.selectedWorldClock;
  }

  @Selector([GlobalState.clockItems, GlobalState.selectedClockItemId])
  static selectedClockItem(items: ClockItem[], id: string): ClockItem {
    if (id === null) {
      return items[0];
    } else {
      return items.find(item => item.name === id);
    }
  }

  @Selector([GlobalState, UserState.selectedClient, UserState.permissionMap])
  static navItems(state: GlobalStateModel, client: Client, permissionMap: PermissionMap): NavItem[] {
    if (client) {
      return state.sideNavItems.filter(item => permissionMap[client.role]?.filter(permission => permission.endsWith(item.module)).length > 0);
    }
    return [];
  }

  @Selector([GlobalState])
  static deviceType(state: GlobalStateModel): string {
    return state.deviceType;
  }

  @Selector([GlobalState])
  static sideNavMode(state: GlobalStateModel): string {
    return state.sideNavMode;
  }

  @Selector([GlobalState])
  static sideNavOpen(state: GlobalStateModel): boolean {
    return state.sideNavOpen;
  }

  @Selector([GlobalState])
  static sideNavLocked(state: GlobalStateModel): boolean {
    return state.sideNavLocked;
  }

  @Action(DeviceType)
  changeDeviceType(ctx: StateContext<GlobalStateModel>, { deviceType }: DeviceType): GlobalStateModel {
    return ctx.patchState({
      deviceType,
      sideNavMode: deviceType === 'desktop' ? 'side' : 'over',
      sideNavOpen: deviceType === 'desktop',
    });
  }

  @Action(ToggleNavbarOpen)
  toggleNavbarOpen(ctx: StateContext<GlobalStateModel>): GlobalStateModel {
    const { sideNavOpen } = ctx.getState();
    return ctx.patchState({
      sideNavOpen: !sideNavOpen,
    });
  }

  @Action(ToggleLockNavbar)
  toggleNavbarLocked(ctx: StateContext<GlobalStateModel>): GlobalStateModel {
    const { sideNavLocked } = ctx.getState();
    return ctx.patchState({
      sideNavLocked: !sideNavLocked,
    });
  }

  @Action(SelectClockItem)
  selectClockItem(ctx: StateContext<GlobalStateModel>, { id }: SelectClockItem): GlobalStateModel {
    return ctx.patchState({
      selectedWorldClock: id,
    });
  }

  @Action(CopyToClipboard)
  copyToClipboard(_ctx: StateContext<GlobalStateModel>, { payload }: CopyToClipboard): void {
    this.clipboard.copy(payload);
  }
}
