import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { catchError, map, Observable, of } from 'rxjs';
import { StockExchangeModel } from '../../share/model/stock-exchange.model';
import { ApiService } from '../../share/service/api.service';
import { EntityModel } from '../index';
import { NotificationShowMessage } from '../notifications/notifications.actions';
import { entityMapper, getInitialState } from '../utils/entity-mapper';
import { LoadStockExchanges } from './stock-exchange.actions';
import { StockExchangeStateModel } from './stock-exchange.model';

@State<StockExchangeStateModel>({
  name: 'stock_exchange',
  defaults: {
    loaded: false,
    entities: getInitialState(),
  },
})
@Injectable()
export class StockExchangeState {
  constructor(private api: ApiService, private store: Store) {}

  static getRowNodeId(item: StockExchangeModel): string {
    return `${item.stockExchangeId}`;
  }

  @Selector([StockExchangeState])
  static stockExchangeEntities(state: StockExchangeStateModel): EntityModel<StockExchangeModel> {
    return state.entities;
  }

  @Selector([StockExchangeState.stockExchangeEntities])
  static stockExchanges(state: EntityModel<StockExchangeModel>): StockExchangeModel[] {
    return state.ids.map(id => state.entities[id]);
  }

  @Action(LoadStockExchanges)
  loadStockExchanges(ctx: StateContext<StockExchangeStateModel>, { force }): Observable<StockExchangeStateModel> {
    const { loaded } = ctx.getState();
    if (!loaded || force) {
      return this.api.getStockExchanges().pipe(
        map(items => {
          const entities = entityMapper(items, StockExchangeState.getRowNodeId);
          return ctx.patchState({
            loaded: true,
            entities,
          });
        }),
        catchError((e: unknown) => this.store.dispatch(new NotificationShowMessage('error', 'Failed to load stock exchanges', e as Error))),
      );
    }
    return of(ctx.getState());
  }
}
