import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatDrawerMode } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, takeUntil } from 'rxjs';

import { PermissionControlPipe } from '../core/permission-control/permission-control.pipe';
import { messageSerializer } from '../share/utils/notification-serializer.util';
import { CopyToClipboard, SelectClockItem, ToggleLockNavbar, ToggleNavbarOpen } from '../state/global/global.actions';
import { ClockItem, NavItem } from '../state/global/global.model';
import { GlobalState } from '../state/global/global.state';
import { NotificationLevelChanged, NotificationUnreadReset } from '../state/notifications/notifications.actions';
import { MessageLevel, Notification } from '../state/notifications/notifications.model';
import { NotificationsState } from '../state/notifications/notifications.state';
import { Logout, SelectUser } from '../state/user/user.actions';
import { Client } from '../state/user/user.model';
import { UserState } from '../state/user/user.state';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  @Select(GlobalState.deviceType) deviceType$: Observable<string>;

  @Select(GlobalState.sideNavMode) sideNavMode$: Observable<MatDrawerMode>;

  @Select(GlobalState.sideNavOpen) sideNavOpen$: Observable<boolean>;

  @Select(GlobalState.sideNavLocked) sideNavLocked$: Observable<boolean>;

  @Select(GlobalState.navItems) navListItems$: Observable<NavItem[]>;

  @Select(GlobalState.clockItems) clockItems$: Observable<ClockItem[]>;

  @Select(GlobalState.selectedClockItem) selectedClockItem$: Observable<ClockItem>;

  @Select(NotificationsState.unreadCount) notificationCount$: Observable<number>;

  @Select(NotificationsState.notifications) notifications$: Observable<Notification[]>;

  @Select(NotificationsState.level) notificationLevel$: Observable<MessageLevel>;

  @Select(UserState.logo) selectedLogo$: Observable<string>;

  @Select(UserState.clients) clients$: Observable<Client[]>;

  @Select(UserState.selectedClient) selectedClient$: Observable<Client | null>;

  @Select(UserState.clientIdSelected) selectedClientId$: Observable<string>;

  notificationLevels = [
    {
      level: 'all',
      icon: 'library_books',
    },
    {
      level: 'success',
      icon: 'check_circle',
    },
    {
      level: 'error',
      icon: 'error_outline',
    },
  ];

  destroy$ = new Subject<void>();

  constructor(private router: Router, private route: ActivatedRoute, private readonly store: Store, private permissionPipe: PermissionControlPipe) {}

  ngOnInit(): void {
    this.selectedClientId$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      const currentUrl = this.router.url;
      const routeData = this.route.snapshot.firstChild.data;
      const module = routeData.module;
      const scope = routeData.neededPermission || undefined;
      const result = this.permissionPipe.transform(module, scope);
      if (result) {
        this.router.navigateByUrl('/').then(() => {
          if (currentUrl === '/fallback') {
            const availableRoutes = this.store.selectSnapshot(GlobalState.navItems);
            if (availableRoutes.length > 0) {
              this.router.navigate(availableRoutes[0].path);
            }
          } else {
            this.router.navigate([currentUrl]);
          }
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  dispatchToggleLockNavbar(): void {
    this.store.dispatch(new ToggleLockNavbar());
  }

  dispatchToggleNavbarOpen(): void {
    this.store.dispatch(new ToggleNavbarOpen());
  }

  dispatchSelectUser(selectId: number | string): void {
    this.store.dispatch(new SelectUser(selectId));
  }

  dispatchSelectClockItem(id: string): void {
    this.store.dispatch(new SelectClockItem(id));
  }

  dispatchUserLogout(): void {
    this.store.dispatch(new Logout());
  }

  /**
   * Dispatch reset read counter
   */
  dispatchResetUnreadCount(): void {
    this.store.dispatch(new NotificationUnreadReset());
  }

  /**
   * Dispatch notification level changed
   *
   * @param item Selected notification level
   */
  dispatchNotificationFilterChange(item: MatButtonToggleChange): void {
    this.store.dispatch(new NotificationLevelChanged(item.value));
  }

  /**
   * Copy message to clipboard
   *
   * @param event MouseEvent
   * @param notification Message to copy
   */
  dispatchCopyToClipboard(event: MouseEvent, notification: Notification): void {
    event.stopPropagation();
    const message = messageSerializer(notification);
    this.store.dispatch(new CopyToClipboard(message));
  }
}
