import { ColDef, GridOptions, ICellRendererParams, MenuItemDef, SideBarDef } from '@ag-grid-community/core';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';

import { ResetGridState } from '../core/grid-state-saver/grid-state-saver.actions';
import { ConfirmModalComponent } from '../share/components/confirm-modal/confirm-modal.component';
import { LoadingIndicatorComponent } from '../share/components/loading-indicator/loading-indicator.component';
import { TableCellButtonComponent } from '../share/components/table-cell-button/table-cell-button.component';
import { SecurityModel } from '../share/model/security.model';
import { ApiService } from '../share/service/api.service';
import { DispatchAddSecurity, DispatchDeleteSecurity, DispatchEditSecurity, SecuritiesLoad } from '../state/securities/securities.actions';
import { SecuritiesState } from '../state/securities/securities.state';
import { UserState } from '../state/user/user.state';
import { EditSecurityModalComponent } from './edit-security-modal/edit-security-modal.component';
import { SecurityListingModalComponent } from './security-listing-modal/security-listing-modal.component';

@Component({
  selector: 'app-securities',
  templateUrl: './securities.component.html',
  styleUrls: ['./securities.component.scss'],
})
export class SecuritiesComponent implements OnInit {
  @Select(SecuritiesState.securityLoaded) securities$: Observable<SecurityModel[]>;

  @Select(UserState.clientIdSelected) selectedClient$: Observable<number | string>;

  destroy$ = new Subject<void>();

  gridOptions: GridOptions = {
    pagination: true,
    getRowId: params => SecuritiesState.getRowNodeId(params.data),
    components: {
      tbButton: TableCellButtonComponent,
    },
    loadingOverlayComponent: LoadingIndicatorComponent,
    loadingOverlayComponentParams: {
      loading: 'true',
    },
  };

  // default column options
  gridColumnDefaults: ColDef = {
    filter: 'agMultiColumnFilter',
    sortable: true,
    resizable: true,
    menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
  };

  // sidebar definition
  gridSidebarDefs: SideBarDef = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressPivotMode: true,
          suppressPivots: true,
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
      {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel',
      },
    ],
  };

  // column definition
  columnDefs = [
    {
      field: 'isin',
      headerName: 'ISIN',
      width: 275,
    },
    {
      field: 'name',
      width: 275,
    },
    {
      field: 'country',
      width: 275,
    },
    {
      field: 'assetClass',
      width: 275,
    },
    {
      field: 'securityType',
      width: 275,
    },
    {
      cellRenderer: 'tbButton',
      suppressColumnsToolPanel: true,
      suppressMenu: true,
      filter: false,
      width: 125,
      sortable: false,
      resizable: false,
      cellClass: 'fill-content disable-highlight delete-btn-sec',
      cellRendererParams: () => {
        return {
          buttonName: 'Listing',
          onClick: (_event: MouseEvent, params: ICellRendererParams) => {
            this.securityListingModal(params.node.id);
          },
        };
      },
    },
    {
      cellRenderer: 'tbButton',
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
      suppressMenu: true,
      filter: false,
      width: 125,
      sortable: false,
      resizable: false,
      cellClass: 'fill-content disable-highlight edit-btn-sec',
      cellRendererParams: () => {
        return {
          buttonName: 'Edit',
          onClick: (_event: MouseEvent, params: ICellRendererParams) => {
            this.addOrEditSecurityModal(params.node.id);
          },
        };
      },
    },
    {
      cellRenderer: 'tbButton',
      suppressColumnsToolPanel: true,
      suppressMenu: true,
      filter: false,
      width: 125,
      sortable: false,
      resizable: false,
      cellClass: 'fill-content disable-highlight delete-btn-sec',
      cellRendererParams: () => {
        return {
          buttonName: 'Delete',
          permissionModule: 'securities',
          permissionScope: 'WRITE',
          color: 'warn',
          onClick: (_event: MouseEvent, params: ICellRendererParams) => {
            this.deleteSecurityModal(params.node.id);
          },
        };
      },
    },
  ];

  constructor(private store: Store, public api: ApiService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.contextMenuItems = this.contextMenuItems.bind(this);
    this.store.dispatch(new SecuritiesLoad());
  }

  /**
   * Display modal to add or edit security
   *
   * @param securityId (Optional) existing security to edit
   */
  addOrEditSecurityModal(securityId: string): void {
    this.dialog
      .open(EditSecurityModalComponent, {
        width: '600px',
        data: {
          edit: securityId,
          securityId,
        },
      })
      .afterClosed()
      .subscribe((data: SecurityModel) => {
        if (data) {
          if (securityId !== null) {
            this.store.dispatch(new DispatchEditSecurity(data));
          } else {
            this.store.dispatch(new DispatchAddSecurity(data));
          }
        }
      });
  }

  /**
   * Display modal to delete security
   *
   * @param securityId Existing security to delete
   */
  deleteSecurityModal(securityId: string): void {
    const security = this.store.selectSnapshot(SecuritiesState.getSecurityById(securityId));
    this.dialog
      .open(ConfirmModalComponent, {
        width: '600px',
        data: {
          securityId,
          title: 'Delete Security',
          text: `Do you really want to delete the security ${security?.name} ?`,
          buttons: [
            { text: 'Cancel', type: 'cancel' },
            { text: 'Delete', type: 'submit', color: 'warn' },
          ],
        },
      })
      .afterClosed()
      .subscribe(data => {
        if (data === 'submit') {
          this.store.dispatch(new DispatchDeleteSecurity(securityId));
        }
      });
  }

  /**
   * Display modal for security listing
   *
   * @param securityId existing security to edit
   */
  securityListingModal(securityId: string): void {
    this.dialog.open(SecurityListingModalComponent, {
      width: '600px',
      data: {
        securityId,
      },
    });
  }

  /**
   * context menu item generator
   */
  contextMenuItems(): (MenuItemDef | string)[] {
    const resetState = {
      name: 'Reset Grid',
      icon: 'refresh',
      action: () => this.store.dispatch(new ResetGridState('securities.gridState')),
    };
    return ['copy', 'export', resetState];
  }
}
