<h1 mat-dialog-title>Validation</h1>
<app-loading-indicator storePath="indexActions.loadRevalidationResult">Processing data...</app-loading-indicator>
<app-loading-indicator storePath="indexActions.loadRevalidationResultError">Server response an error...
</app-loading-indicator>
<mat-dialog-content>
  <div *ngFor="let validationResult of result | async" class="validation-content-wrapper">
    <div class="status">
      <ng-container [ngSwitch]="validationResult.validationStatus">
        <mat-icon *ngSwitchCase="'Success'">check_circle</mat-icon>
        <mat-icon *ngSwitchCase="'PartialSuccess'">rule</mat-icon>
        <mat-icon *ngSwitchCase="'Failure'">error</mat-icon>
      </ng-container>
      <span>{{validationResult.statusMessage}}</span>
    </div>
    <mat-form-field>
      <mat-label>Fixing Date</mat-label>
      <input [ngModel]="validationResult?.fixingDate" disabled matInput>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Effective Date</mat-label>
      <input [ngModel]="validationResult?.effectiveDate" disabled matInput>
    </mat-form-field>
    <mat-checkbox [checked]="validationResult?.timespanCreated" disabled>Timespan created</mat-checkbox>
    <mat-label>Problematic Securities</mat-label>
    <div class="table-wrapper">
      <table [dataSource]="validationResult?.problematicSecurities || []" mat-table multiTemplateDataRows>
        <ng-container matColumnDef="expanded">
          <th *matHeaderCellDef mat-header-cell></th>
          <td *matCellDef="let element" mat-cell>
            <mat-icon>{{element === expandedElement ? 'expand_less' : 'expand_more'}}</mat-icon>
          </td>
        </ng-container>
        <ng-container matColumnDef="isin">
          <th *matHeaderCellDef mat-header-cell>ISIN</th>
          <td *matCellDef="let element" mat-cell>{{element.isin}}</td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th *matHeaderCellDef mat-header-cell>Name</th>
          <td *matCellDef="let element" mat-cell>{{element.name}}</td>
        </ng-container>
        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td *matCellDef="let element" [attr.colspan]="columnsToDisplay.length" mat-cell>
            <mat-list [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'" class="element-detail"
                      dense
                      role="list">
              <h3 matSubheader>Found problems</h3>
              <mat-list-item *ngIf="!element?.closePriceProviderConfigured">
                <mat-icon mat-list-icon>info</mat-icon>
                <div mat-line>Close Price Provider not configured</div>
              </mat-list-item>
              <mat-list-item *ngIf="!element?.realtimePriceProviderConfigured">
                <mat-icon mat-list-icon>info</mat-icon>
                <div mat-line>Realtime Price Provider not configured</div>
              </mat-list-item>
              <mat-list-item *ngIf="!element?.closePriceIndexConfigured">
                <mat-icon mat-list-icon>info</mat-icon>
                <div mat-line>Close Price Index not configured</div>
              </mat-list-item>
              <mat-list-item *ngIf="!element?.realtimePriceIndexConfigured">
                <mat-icon mat-list-icon>info</mat-icon>
                <div mat-line>Realtime Price Index not configured</div>
              </mat-list-item>
              <mat-list-item *ngIf="!element?.closeFxIndexConfigured">
                <mat-icon mat-list-icon>info</mat-icon>
                <div mat-line>Close FX Index not configured</div>
              </mat-list-item>
              <mat-list-item *ngIf="!element?.realtimeFxIndexConfigured">
                <mat-icon mat-list-icon>info</mat-icon>
                <div mat-line>Realtime FX Index not configured</div>
              </mat-list-item>
              <mat-list-item *ngIf="!element?.fixingClosePriceAvailable">
                <mat-icon mat-list-icon>info</mat-icon>
                <div mat-line>Fixing Close Price unavailable</div>
              </mat-list-item>
              <mat-list-item *ngIf="!element?.fixingFxRateAvailable">
                <mat-icon mat-list-icon>info</mat-icon>
                <div mat-line>Fixing FX rate unavailable</div>
              </mat-list-item>
            </mat-list>
          </td>
        </ng-container>
        <tr *matHeaderRowDef="columnsToDisplay" mat-header-row></tr>
        <tr (click)="expandedElement = expandedElement === element ? null : element"
            *matRowDef="let element; columns: columnsToDisplay;"
            [class.expanded-row]="expandedElement === element"
            class="element-row"
            mat-row>
        </tr>
        <tr *matRowDef="let element; let row; columns: ['expandedDetail']" [class.hidden]="expandedElement !== element"
            class="detail-row"
            mat-row></tr>
      </table>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="modal-buttons">
  <button mat-button mat-dialog-close>Ok</button>
</mat-dialog-actions>
