import { Injectable } from '@angular/core';
import { getActionTypeFromInstance, NgxsNextPluginFn, NgxsPlugin, setValue } from '@ngxs/store';
import { ResetGridState, SetGridState } from './grid-state-saver.actions';

@Injectable()
export class GridStateSaverModulePlugin implements NgxsPlugin {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handle(state: unknown, action: any, next: NgxsNextPluginFn) {
    const type = getActionTypeFromInstance(action);
    let nextState = state;

    if (type === ResetGridState.type) {
      nextState = setValue(nextState, action.statePath, null);
    }

    if (type === SetGridState.type) {
      nextState = setValue(nextState, action.statePath, action.payload);
    }

    return next(nextState, action);
  }
}
