export interface ProviderModel {
  providerId: number;
  name: string;
  dataProviderId: number;
  providerType: string;
}

export interface ProviderQueryModel {
  providerQueryId: number;
  providerId: number;
  providerName: string;
  name: string;
  priceType: PriceType | string;
  queryType: QueryType | string;
  baseCcy?: string;
  fetchAll?: boolean;
}

export enum QueryType {
  char = 'CHAR',
  share = 'SHARE',
  fx = 'FX',
}

export enum PriceType {
  realtime = 'REALTIME',
  close = 'CLOSE',
}
