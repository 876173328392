import { Directive, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject, combineLatestWith, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Permissions } from '../../share/model/permission.model';
import { UserState } from '../../state/user/user.state';

@Directive({
  selector: '[appPermissionControl]',
})
export class PermissionControlDirective implements OnInit, OnDestroy {
  @Input() permissionModule: string;

  @Input() set permissionScope(scope: string) {
    this.permissionScope$.next(Permissions[scope]);
  }

  @HostBinding('class.hidden') hidden = false;

  /**
   * Destroy all subscriptions after call next function
   */
  destroy$ = new Subject<void>();

  permissionScope$ = new BehaviorSubject<string>(Permissions.NONE);

  constructor(private store: Store) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.destroy$ = null;
  }

  ngOnInit(): void {
    if (this.permissionModule) {
      this.store
        .select(UserState.checkUserRole(this.permissionModule))
        .pipe(combineLatestWith(this.permissionScope$), takeUntil(this.destroy$))
        .subscribe(([permissions, scope]) => {
          const contains = permissions.filter(item => item.startsWith(scope));
          if (scope === Permissions.NONE) {
            this.hidden = false;
          } else {
            this.hidden = contains.length === 0;
          }
        });
    }
  }
}
