import { Component, Inject } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ConfirmDataModel {
  title: string;
  text: string;
  buttons: { text: string; type: string; color: ThemePalette }[];
}

@Component({
  selector: 'app-delete-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent {
  modalTitle: string = this.payload.title;

  modalText: string = this.payload.text;

  buttons: { text: string; type: string; color: ThemePalette }[] = this.payload.buttons;

  constructor(@Inject(MAT_DIALOG_DATA) public payload: ConfirmDataModel) {}
}
