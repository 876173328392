import { Injectable } from '@angular/core';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { MasterIndexModel } from '../../share/model/master-index.model';
import { ApiService } from '../../share/service/api.service';
import { SelectUser } from '../user/user.actions';
import { LoadMasterIndex } from './master-index.action';
import { MasterIndexStateModel } from './master-index.model';

@State<MasterIndexStateModel>({
  name: 'masterIndex',
  defaults: {
    loaded: false,
    entities: MasterIndexState.entityAdapter.getInitialState(),
  },
})
@Injectable()
export class MasterIndexState {
  /**
   * Entity state adapter
   */
  static entityAdapter = createEntityAdapter<MasterIndexModel>({
    selectId: MasterIndexState.getRowNodeId,
  });

  constructor(public api: ApiService) {}

  /**
   * Generate unique ID
   *
   * @param item Model
   */
  static getRowNodeId(item: MasterIndexModel): string {
    return `${item.masterdataId}`;
  }

  @Selector([MasterIndexState])
  static entitiesSelector(state: MasterIndexStateModel): EntityState<MasterIndexModel> {
    return state.entities;
  }

  @Selector([MasterIndexState.entitiesSelector])
  static masterIndices(state: EntityState<MasterIndexModel>): MasterIndexModel[] {
    return this.entityAdapter.getSelectors().selectAll(state);
  }

  @Action(LoadMasterIndex)
  loadData(ctx: StateContext<MasterIndexStateModel>, { force }: LoadMasterIndex): Observable<MasterIndexStateModel> {
    const { loaded } = ctx.getState();
    if (!loaded || force) {
      return this.api.getMasterIndices().pipe(
        map(items => {
          const { entities } = ctx.getState();
          const newEntities = MasterIndexState.entityAdapter.setAll(items, entities);
          return ctx.patchState({
            entities: newEntities,
            loaded: true,
          });
        }),
      );
    }
    return of(ctx.getState());
  }

  /**
   * Reset state on client change
   *
   * @param ctx State context
   */
  @Action(SelectUser)
  selectedClientChanged(ctx: StateContext<MasterIndexStateModel>): MasterIndexStateModel {
    return ctx.patchState({
      loaded: false,
      entities: MasterIndexState.entityAdapter.getInitialState(),
    });
  }
}
