<ng-container *ngLet="config$ | async as config">
  <button (click)="buttonClick($event)"
          [color]="config.color"
          [disabled]="config.disabled"
          [permissionModule]="config.permissionModule"
          [permissionScope]="config.permissionScope"
          class="table-button"
          appPermissionControl
          mat-stroked-button>
    <mat-icon *ngIf="config.icon">{{config.icon}}</mat-icon>
    {{config.buttonName}}
  </button>
  <input #fileInput
         (change)="handleFileChange($event)"
         [accept]="config.accept"
         [multiple]="config.multiple"
         class="table-button-fileselect"
         hidden
         type="file">
</ng-container>

