import { EntityModel } from '../index';

type IdSelector = (T) => string;

/**
 * Helper function to map items to entity map
 *
 * @param items
 * @param selector
 */
export const entityMapper = <T>(items: T[], selector: IdSelector): EntityModel<T> =>
  items.reduce((acc: EntityModel<T>, item: T) => {
    const id = selector(item);
    acc.ids.push(id);
    acc.entities[id] = item;
    return acc;
  }, getInitialState<T>());

/**
 * Helper function get initial entity map model
 */
export const getInitialState = <T>(): EntityModel<T> => {
  return {
    ids: [],
    entities: {},
    selected: [],
  };
};

/**
 * Helper function to add single item to entity state
 *
 * @param state Original entity state
 * @param id Item ID
 * @param item Item to add
 */
export const addOne = <T>(state: EntityModel<T>, id: string, item: T): EntityModel<T> => {
  return {
    ...state,
    ids: [id, ...state.ids],
    entities: {
      ...state.entities,
      [id]: item,
    },
  };
};

export const removeOne = <T>(state: EntityModel<T>, id: string): EntityModel<T> => {
  const copy = {
    ...state,
    ids: state.ids.filter(key => key !== id),
    entities: {
      ...state.entities,
    },
  };

  delete copy.entities[id];
  return copy;
};

export const updateOne = <T>(state: EntityModel<T>, id: string, item: T): EntityModel<T> => {
  return {
    ...state,
    entities: {
      ...state.entities,
      [id]: item,
    },
  };
};
