import { Route } from '@angular/router';
import { ManageIndexActionsComponent } from './manage-index-actions/manage-index-actions.component';
import { ManageIndexConfigurationComponent } from './manage-index-configuration/manage-index-configuration.component';

export interface TabRoute extends Route {
  label?: string;
  hidden?: boolean;
}

export const childRoutes: TabRoute[] = [
  {
    label: 'Actions',
    path: 'action',
    component: ManageIndexActionsComponent,
  },
  {
    label: 'Input Data',
    path: 'configuration',
    component: ManageIndexConfigurationComponent,
  },
  {
    hidden: true,
    redirectTo: 'action',
    path: '**',
  },
];
