import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../share/service/auth.service';
import { UserState } from '../state/user/user.state';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  @Select(UserState.permissionLoaded) permissionLoaded$: Observable<boolean>;

  destroy$ = new Subject<void>();

  constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.authService.showLogin();
    this.permissionLoaded$.pipe(takeUntil(this.destroy$)).subscribe(async permissionLoaded => {
      if (permissionLoaded) {
        const url = this.route.snapshot.queryParams.url;
        await this.router.navigate([url ? url : '/']);
      }
    });
  }

  ngOnDestroy(): void {
    this.authService.hideLogin();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
