import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CurrencyModel } from '../../share/model/currency.model';
import { IndexConfigurationModel } from '../../share/model/index-configuration.model';
import { MasterIndexModel } from '../../share/model/master-index.model';
import { PriceType, ProviderQueryModel, QueryType } from '../../share/model/provider.model';
import { LoadCurrencies } from '../../state/currency/currency.actions';
import { CurrencyState } from '../../state/currency/currency.state';
import { LoadConfigurations } from '../../state/manage-index-configuration/manage-index-configuration.action';
import { ManageIndexConfigurationState } from '../../state/manage-index-configuration/manage-index-configuration.state';
import { LoadMasterIndex } from '../../state/master-index/master-index.action';
import { MasterIndexState } from '../../state/master-index/master-index.state';
import { LoadProviderQueries } from '../../state/provider-query/provider-query.action';
import { ProviderQueryState } from '../../state/provider-query/provider-query.state';

interface ManageIndexConfigurationDetailModalData {
  configId?: string;
  edit?: boolean;
}

interface FormData {
  configId?: number;
  masterdataId?: number;
  name: string;
  currency: string;
  closePrices: number;
  realtimePrices: number;
  closeFX: number;
  realtimeFX: number;
}

@Component({
  selector: 'app-manage-index-configuration-detail-modal',
  templateUrl: './manage-index-configuration-detail-modal.component.html',
  styleUrls: ['./manage-index-configuration-detail-modal.component.scss'],
})
export class ManageIndexConfigurationDetailModalComponent implements OnInit {
  @Select(CurrencyState.currencies) currencies$: Observable<CurrencyModel[]>;

  @Select(MasterIndexState.masterIndices) indices$: Observable<MasterIndexModel[]>;

  @Select(ProviderQueryState.filteredQueries(QueryType.share, PriceType.close)) closePriceQueries$: Observable<ProviderQueryModel[]>;

  @Select(ProviderQueryState.filteredQueries(QueryType.share, PriceType.realtime)) realtimePriceQueries$: Observable<ProviderQueryModel[]>;

  @Select(ProviderQueryState.filteredQueries(QueryType.fx, PriceType.close)) closeFxQueries$: Observable<ProviderQueryModel[]>;

  @Select(ProviderQueryState.filteredQueries(QueryType.fx, PriceType.realtime)) realtimeFxQueries$: Observable<ProviderQueryModel[]>;

  configIdChanged$ = new BehaviorSubject<string | number>(null);

  selectedConfig$ = this.configIdChanged$.pipe(
    filter(id => !!id),
    map(id => this.store.selectSnapshot(ManageIndexConfigurationState.getConfigById(id))),
  );

  columnsToDisplay = ['variationId', 'currency', 'variationName'];

  constructor(public store: Store, public dialogRef: MatDialogRef<ManageIndexConfigurationDetailModalComponent>, @Inject(MAT_DIALOG_DATA) public data: ManageIndexConfigurationDetailModalData) {}

  ngOnInit(): void {
    this.configIdChanged$.next(this.data?.configId);
    this.store.dispatch(new LoadCurrencies());
    this.store.dispatch(new LoadMasterIndex());
    this.store.dispatch(new LoadProviderQueries());
    this.store.dispatch(new LoadConfigurations());
  }

  /**
   * Submit changes to parent component
   *
   * @param values Form values
   */
  dispatchSaveConfig(values: FormData): void {
    const submitData: IndexConfigurationModel = {
      masterdataId: values.masterdataId,
      name: values.name,
      currency: values.currency,
      defaultProviders: {
        closePrices: values.closePrices,
        closeFX: values.closeFX,
        realtimeFX: values.realtimeFX,
        realtimePrices: values.realtimePrices,
      },
    };

    if (this.data.edit) {
      const { masterdataId, configId } = this.store.selectSnapshot(ManageIndexConfigurationState.getConfigById(this.configIdChanged$.value));
      submitData.configId = configId;
      submitData.masterdataId = masterdataId;
    }
    this.dialogRef.close(submitData);
  }
}
