import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { lastValueFrom, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { SecurityModel, SecurityType } from '../../share/model/security.model';
import { ApiService } from '../../share/service/api.service';
import { EntityModel } from '../index';
import { NotificationShowMessage } from '../notifications/notifications.actions';
import { wrapSuccess } from '../notifications/notifications.helper';
import { addOne, entityMapper, getInitialState, removeOne, updateOne } from '../utils/entity-mapper';
import { DispatchAddSecurity, DispatchDeleteSecurity, DispatchEditSecurity, LoadSecurityType, SecuritiesLoad } from './securities.actions';
import { SecuritiesStateModel } from './securities.model';

@State<SecuritiesStateModel>({
  name: 'securities',
  defaults: {
    loaded: false,
    securityEntities: getInitialState(),
    securityType: [],
    securityTypeLoaded: false,
    gridState: null,
  },
})
@Injectable()
export class SecuritiesState {
  constructor(public api: ApiService, private store: Store) {}

  /**
   * Get security by id
   *
   * @param id Unique ID
   */
  static getSecurityById(id: string | number) {
    return createSelector([SecuritiesState.securityEntities], (state: EntityModel<SecurityModel>): SecurityModel => state.entities[id]);
  }

  /**
   * Generate node id for securities
   *
   * @param item Model
   */
  static getRowNodeId(item: SecurityModel): string {
    return String(item.securityId);
  }

  /**
   * Security search operator
   *
   * @param limit Limit result
   */
  static securitySearchOperator(limit = 50): ([securities, search]: [SecurityModel[], string]) => SecurityModel[] {
    return ([securities, search]): SecurityModel[] =>
      securities
        .filter(item => {
          if (search.length >= 2) {
            const isin = item.isin?.toLowerCase();
            const name = item.name?.toLowerCase();
            return isin?.includes(search.toLowerCase()) || name?.includes(search.toLowerCase());
          }
          return true;
        })
        .slice(0, limit);
  }

  @Selector([SecuritiesState])
  static securityEntities(state: SecuritiesStateModel): EntityModel<SecurityModel> {
    return state.securityEntities;
  }

  @Selector([SecuritiesState.securityEntities])
  static securities(entities: EntityModel<SecurityModel>): SecurityModel[] {
    return entities.ids.map(id => entities.entities[id]);
  }

  @Selector([SecuritiesState, SecuritiesState.securities])
  static securityLoaded(state: SecuritiesStateModel, securities: SecurityModel[]): SecurityModel[] {
    return state.loaded ? securities : null;
  }

  @Selector([SecuritiesState])
  static securityType(state: SecuritiesStateModel): SecurityType[] {
    return state.securityType;
  }

  /**
   * Get security type
   *
   * @param ctx State context
   * @param force Payload to force load securities
   */
  @Action(LoadSecurityType)
  getSecurityType(ctx: StateContext<SecuritiesStateModel>, { force }: LoadSecurityType): Observable<SecuritiesStateModel> {
    const { securityTypeLoaded } = ctx.getState();
    if (force || !securityTypeLoaded) {
      return this.api.getSecurityTypes().pipe(
        map(securityType =>
          ctx.patchState({
            securityType,
            securityTypeLoaded: true,
          }),
        ),
      );
    }
    return of(ctx.getState());
  }

  /**
   * Action to load securities
   *
   * @param ctx State context
   * @param force Payload to force load securities
   */
  @Action(SecuritiesLoad)
  async loadSecurities(ctx: StateContext<SecuritiesStateModel>, { force }: SecuritiesLoad): Promise<void> {
    const { loaded } = ctx.getState();
    if (force || !loaded) {
      try {
        const result = await lastValueFrom(this.api.findSecurities());
        const securityEntities = entityMapper(result, SecuritiesState.getRowNodeId);
        ctx.patchState({
          securityEntities,
          loaded: true,
        });
      } catch (e) {
        this.store.dispatch(new NotificationShowMessage('error', 'Failed to load securities', e));
      }
    }
  }

  @Action(DispatchAddSecurity)
  dispatchAddSecurity(ctx: StateContext<SecuritiesStateModel>, { payload }: DispatchAddSecurity): Observable<SecuritiesStateModel> {
    return this.api.addNewSecurity(payload).pipe(
      map(security => {
        const { securityEntities } = ctx.getState();
        const id = SecuritiesState.getRowNodeId(security);
        const newSecurityEntities = addOne(securityEntities, id, security);
        return ctx.patchState({
          securityEntities: newSecurityEntities,
        });
      }),
      tap(() => wrapSuccess(this.store, 'New Security is successfully Added.')),
    );
  }

  @Action(DispatchEditSecurity)
  dispatchEditSecurity(ctx: StateContext<SecuritiesStateModel>, { payload }: DispatchEditSecurity): Observable<SecuritiesStateModel> {
    return this.api.editSecurity(payload).pipe(
      map(security => {
        const { securityEntities } = ctx.getState();
        const id = SecuritiesState.getRowNodeId(security);
        const newSecurityEntities = updateOne(securityEntities, id, security);
        return ctx.patchState({
          securityEntities: newSecurityEntities,
        });
      }),
      tap(() => wrapSuccess(this.store, `Security (Id #${payload.securityId}) is successfully updated.`)),
    );
  }

  @Action(DispatchDeleteSecurity)
  dispatchDeleteSecurity(ctx: StateContext<SecuritiesStateModel>, { payload }: DispatchDeleteSecurity) {
    const security = this.store.selectSnapshot(SecuritiesState.getSecurityById(payload));
    return this.api.deleteSecurity(security.securityId).pipe(
      map(() => {
        const { securityEntities } = ctx.getState();
        const newSecurityEntities = removeOne(securityEntities, payload);
        return ctx.patchState({
          securityEntities: newSecurityEntities,
        });
      }),
      tap(() => wrapSuccess(this.store, `Security (Id #${payload}) is successfully deleted.`)),
    );
  }
}
