import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { DeviceType } from './state/global/global.actions';
import { GlobalState } from './state/global/global.state';
import { UpdateService } from './core/update-service/update.service';

@Component({
  selector: 'app-root',
  template: `
    <div class="portal" [ngClass]="deviceType$ | async">
      <router-outlet></router-outlet>
    </div>
  `,
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @Select(GlobalState.deviceType) deviceType$: Observable<string>;

  constructor(private updateService: UpdateService, private readonly breakpointObserver: BreakpointObserver, private readonly store: Store) {}

  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge]).subscribe(result => this.store.dispatch(new DeviceType(result.matches ? 'desktop' : 'mobile')));
  }
}
