export class DeviceType {
  static readonly type = '[Global] device type';

  constructor(public deviceType: string) {}
}

export class ToggleNavbarOpen {
  static readonly type = '[Global] toggle sidenav open';
}

export class ToggleLockNavbar {
  static readonly type = '[Global] toggle lock sidenav';
}

export class SelectClockItem {
  static readonly type = '[Global] select clock item';

  constructor(public readonly id: string) {}
}

export class CopyToClipboard {
  static readonly type = '[Global] copy value to clipboard';

  constructor(public readonly payload: string) {}
}
