import { ColDef, GridOptions, ICellRendererParams, MenuItemDef, SideBarDef, ValueFormatterParams } from '@ag-grid-community/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { utc } from 'dayjs';
import { Observable, Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';
import { ResetGridState } from 'src/app/core/grid-state-saver/grid-state-saver.actions';

import { TableCellButtonComponent } from '../../share/components/table-cell-button/table-cell-button.component';
import { TableCellIconComponent } from '../../share/components/table-cell-icon/table-cell-icon.component';
import { UserState } from '../../state/user/user.state';
import { LoadIndexActions, RevalidateVariation, UploadVariationRebalanceFile } from '../../state/variation-actions/variation-actions.actions';
import { VariationRestatementType } from '../../state/variation-actions/variation-actions.model';
import { VariationActionsState } from '../../state/variation-actions/variation-actions.state';
import { ManageIndexActionsValidationModalComponent } from '../manage-index-actions-validation-modal/manage-index-actions-validation-modal.component';

@Component({
  selector: 'app-manage-index-actions',
  templateUrl: './manage-index-actions.component.html',
  styleUrls: ['./manage-index-actions.component.scss'],
})
export class ManageIndexActionsComponent implements OnInit, OnDestroy {
  @Select(VariationActionsState.variationRestatements) variationRestatements$: Observable<VariationRestatementType[]>;

  @Select(UserState.clientIdSelected) selectedClient$: Observable<number | string>;

  destroy$ = new Subject<void>();

  // grid options
  gridOptions: GridOptions = {
    animateRows: false,
    multiSortKey: 'ctrl',
    getRowId: params => VariationActionsState.getRowNodeId(params.data),
    components: {
      tbIcon: TableCellIconComponent,
      tbButton: TableCellButtonComponent,
    },
  };

  // sidebar configuration
  gridSidebarDefs: SideBarDef = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressPivotMode: true,
          suppressPivots: true,
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
      {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel',
      },
    ],
  };

  gridColumnDefaults: ColDef = {
    filter: 'agMultiColumnFilter',
    sortable: true,
    resizable: true,
    menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
  };

  columnDefs: ColDef[] = [
    {
      headerName: 'Index Name',
      field: 'indexName',
      pinned: 'left',
    },
    {
      headerName: 'Variant',
      field: 'name',
      minWidth: 180,
      pinned: 'left',
    },
    {
      headerName: 'Currency',
      field: 'currency',
      pinned: 'left',
    },
    {
      headerName: 'Ticker',
      field: 'ticker',
    },
    {
      headerName: 'Upcoming Actions',
      field: 'upcomingActions',
      cellRenderer: 'tbIcon',
      valueFormatter: params => (params.value ? 'check_circle' : null),
    },
    {
      headerName: 'Rebalance Frequency',
      field: 'rebalanceFrequency',
    },
    {
      headerName: 'Next Rebalance',
      field: 'nextRebalance',
      valueFormatter: (param: ValueFormatterParams) => {
        if (param.value) {
          return utc(param.value).format('YYYY-MM-DD');
        }
        return param.value;
      },
    },
    {
      headerName: 'Rebalance Loaded',
      field: 'rebalanceLoaded',
      cellRenderer: 'tbIcon',
      valueFormatter: params => (params.value ? 'check_circle' : null),
    },
    {
      headerName: 'Last Rebalance',
      field: 'lastRebalance',
    },
    {
      cellRenderer: 'tbButton',
      width: 160,
      suppressMenu: true,
      filter: false,
      sortable: false,
      resizable: false,
      suppressColumnsToolPanel: true,
      cellClass: 'fill-content disable-highlight',
      cellRendererParams: {
        buttonName: 'Import Rebalance',
        type: 'file',
        color: 'primary',
        permissionModule: 'corporate_actions_rebalance',
        permissionScope: 'WRITE',
        onClick: (_event: MouseEvent, params: ICellRendererParams, files: FileList): void => {
          this.dispatchImportRebalance(params.data, files[0]);
        },
      },
    },
    {
      cellRenderer: 'tbButton',
      width: 160,
      suppressMenu: true,
      filter: false,
      sortable: false,
      resizable: false,
      suppressColumnsToolPanel: true,
      cellClass: 'fill-content disable-highlight',
      cellRendererParams: (btnParams: ICellRendererParams) => {
        return {
          buttonName: 'Revalidate',
          color: 'primary',
          permissionModule: 'corporate_actions',
          permissionScope: 'WRITE',
          disabled: btnParams.data.upcomingActions,
          onClick: (_event: MouseEvent, params: ICellRendererParams): void => {
            this.dispatchRevalidate(params.data);
          },
        };
      },
    },
  ];

  constructor(private store: Store, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.contextMenuItems = this.contextMenuItems.bind(this);
    this.store.dispatch(new LoadIndexActions());
    this.selectedClient$.pipe(skip(1), takeUntil(this.destroy$)).subscribe(() => this.store.dispatch(new LoadIndexActions(true)));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Dispatch import rebalance file
   *
   * @param data Variation entry
   * @param file Rebalance file
   */
  dispatchImportRebalance(data: VariationRestatementType, file: File): void {
    this.store.dispatch(new UploadVariationRebalanceFile(data.indexId, data.variationId, data.configId, file));
    this.showValidationResult();
  }

  /**
   * Dispatch variation revalidation
   *
   * @param data Variation entry
   */
  dispatchRevalidate(data: VariationRestatementType): void {
    this.store.dispatch(new RevalidateVariation(data.indexId, data.variationId));
    this.showValidationResult();
  }

  /**
   * Show upload validation result screen
   */
  showValidationResult(): void {
    this.dialog.open(ManageIndexActionsValidationModalComponent, {
      width: '600px',
      height: '800px',
    });
  }

  /**
   * Context menu item generator
   *
   */
  contextMenuItems(): (MenuItemDef | string)[] {
    const resetState = {
      name: 'Reset Grid',
      icon: 'refresh',
      action: () => this.store.dispatch(new ResetGridState('indexActions.gridState')),
    };
    return ['copy', 'export', resetState];
  }
}
