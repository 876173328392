import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { Client } from '../../state/user/user.model';
import { UserState } from '../../state/user/user.state';
import { HttpCancelService } from './http-cancel.service';

@Injectable()
export class HttpApiInterceptor implements HttpInterceptor {
  @Select(UserState.selectedClient) selectedClient$: Observable<Client | null>;

  constructor(@Inject('ApiUrl') private apiUrl: string, private store: Store, private httpCancelService: HttpCancelService) {
    this.selectedClient$.pipe(filter(client => client !== null)).subscribe(() => {
      httpCancelService.cancelPendingRequests();
    });
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const clientId = request.headers.get('x-override-clientId') || this.store.selectSnapshot(UserState.clientIdSelected);
    const token = this.store.selectSnapshot(UserState.userAuthToken);
    if (token) {
      return next
        .handle(
          request.clone({
            url: this.apiUrl + request.url,
            headers: request.headers.delete('x-override-clientId'),
            setHeaders: {
              'Authorization': `Bearer ${token}`,
              'X-Client': String(clientId),
            },
          }),
        )
        .pipe(takeUntil(this.httpCancelService.onCancelPendingRequests()));
    }
    return next.handle(request);
  }
}
