import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { GlobalState } from '../../state/global/global.state';
import { PermissionControlPipe } from './permission-control.pipe';

@Injectable({
  providedIn: 'root',
})
export class RouteGuard implements CanActivate {
  constructor(private router: Router, private store: Store, private permissionPipe: PermissionControlPipe) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const module = route.data?.module;
    const scope = route.data?.neededPermission || undefined;
    const result = this.permissionPipe.transform(module, scope);
    if (result) {
      const availableRoutes = this.store.selectSnapshot(GlobalState.navItems);
      if (availableRoutes.length > 0) {
        await this.router.navigate(availableRoutes[0].path);
        return false;
      }
      await this.router.navigateByUrl('/fallback');
      return false;
    }
    return true;
  }
}
