import dayjs from 'dayjs';
import { MessageLevel, MessageType } from './notifications.model';

/**
 * Generic notification action
 */
export class NotificationShowMessage {
  static readonly type = '[Notification] show new notification';

  readonly date = dayjs().toDate();

  constructor(public readonly level: MessageType, public readonly message: string, public readonly error?: Error) {}
}

/**
 * Reset notification unread counter
 */
export class NotificationUnreadReset {
  static readonly type = '[Notification] notification unread reset';
}

/**
 * Change notification level
 */
export class NotificationLevelChanged {
  static readonly type = '[Notification] notification level changed';

  constructor(public level: MessageLevel) {}
}
