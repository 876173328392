import { Component } from '@angular/core';
import { childRoutes } from '../manage-index-child.routes';

@Component({
  selector: 'app-manage-index',
  templateUrl: './manage-index.component.html',
  styleUrls: ['./manage-index.component.scss'],
})
export class ManageIndexComponent {
  routerLinks = childRoutes.filter(item => !item.hidden);
}
