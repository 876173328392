import { Injectable } from '@angular/core';
import { Selector, State } from '@ngxs/store';
import { utc } from 'dayjs';

import { FormModel } from '../../../state';
import { FormState } from '../../../state/utils/form.state';
import { OverviewParentProperties, OverviewParentStateModel } from './overview-parent.model';
import { CorporateActionModel } from '../../../share/model/corporate-action.model';

export const getDuplicateRowKey = (rowData: CorporateActionModel) => `${rowData.isin}.${rowData.corporateActionType}.${rowData.terms}.${rowData.effectiveDate}`;

export const loadDuplicateCount = (corporateActions: CorporateActionModel[]) =>
  corporateActions.reduce((acc, item) => {
    const key = getDuplicateRowKey(item);
    acc[key] = acc[key] ? acc[key] + 1 : 1;
    return acc;
  }, {});

@State<OverviewParentStateModel>({
  name: 'caManageParent',
  defaults: {
    form: new FormState({
      fromDate: utc().subtract(3, 'days').toDate(),
      toDate: utc().add(3, 'days').toDate(),
    }),
  },
})
@Injectable()
export class OverviewParentState {
  @Selector([OverviewParentState])
  static form(state: OverviewParentStateModel): FormModel<OverviewParentProperties> {
    return state.form;
  }

  @Selector([OverviewParentState.form])
  static formModel(state: FormModel<OverviewParentProperties>): OverviewParentProperties {
    return state.model;
  }
}
