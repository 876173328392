import { Directive, HostListener } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'input',
})
export class StopScrollDirective {
  @HostListener('wheel', ['$event'])
  onWheel(event: Event) {
    if ((event.target as HTMLInputElement)?.type === 'number') {
      event.preventDefault();
    }
  }
}
