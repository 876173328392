import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';

import { UserState } from 'src/app/state/user/user.state';
import { Permissions } from '../../share/model/permission.model';

@Pipe({ name: 'permissioncontrol' })
export class PermissionControlPipe implements PipeTransform {
  constructor(private store: Store) {}

  /**
   * Pipe to calculate permissions. Returns false if permitted, otherwise returns true if not permitted
   *
   * @param module Permission module
   * @param permissionScope Permission scope
   */
  transform(module: string, permissionScope = 'NONE'): boolean {
    const scope = Permissions[permissionScope];
    const permissions = this.store.selectSnapshot(UserState.checkUserRole(module));
    const contains = permissions.filter(item => item.startsWith(scope));
    return scope === Permissions.NONE || contains.length === 0;
  }
}
