<div class="login-wrapper">
  <div class="content" id="loginContainer"></div>
</div>
<div class="footer">
  <div class="row-item"></div>
  <div class="row-item">
    <p class="center">Copyright &copy; 2019 Moorgate Benchmarks – All Rights Reserved.</p>
    <p>Moorgate Benchmarks Limited is a company registered in England with company number 11125661 and whose registered office is at 1 Oliver’s Yard, 55-71 City Road, London EC1Y 1HQ</p>
    <p class="center"><a href="https://moorgatebenchmarks.com/" target="_blank">Moorgate Benchmarks</a></p>
  </div>
  <div class="row-item"></div>
</div>
