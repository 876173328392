<form #form="ngForm" (ngSubmit)="dispatchCreatOrEdit(form.value)">
  <h1 mat-dialog-title>{{(editMode$ | async) ? 'Edit' : 'Create'}} Security</h1>
  <mat-dialog-content *ngLet="security$ | async as security" class="content-wrapper">

    <mat-form-field *ngIf="security?.securityId">
      <mat-label>Security ID</mat-label>
      <input [ngModel]="security?.securityId" disabled matInput name="securityId"/>
    </mat-form-field>

    <mat-form-field>
      <mat-label>ISIN</mat-label>
      <input [ngModel]="security?.isin" matInput name="isin" required/>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Name</mat-label>
      <input [ngModel]="security?.name" matInput name="name" required/>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Type</mat-label>
      <mat-select (selectionChange)="selectType($event)" [ngModel]="security?.securityType" name="securityType"
                  required>
        <mat-option *ngFor="let type of (securityTypes$ | async)"
                    [value]="type.name">
          {{type.name}} - {{type.assetClass}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Asset CLass</mat-label>
      <input [ngModel]="selectedSecurityType$ | async" disabled matInput name="assetClass"/>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Country</mat-label>
      <input [ngModel]="security?.country" matInput name="country"/>
    </mat-form-field>

  </mat-dialog-content>
  <div class="modal-buttons" mat-dialog-actions>
    <button [mat-dialog-close]="null" mat-button>Cancel</button>
    <button [disabled]="form.invalid || ('securities' | permissioncontrol: 'WRITE')"
            color="primary"
            [permissionModule]="'securities'"
            [permissionScope]="'WRITE'"
            appPermissionControl
            mat-button type="submit">
      {{(editMode$ | async) ? 'Edit' : 'Create'}}
    </button>
  </div>
</form>
