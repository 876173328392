import { IndexConfigurationModel } from '../../share/model/index-configuration.model';

/**
 * Trigger action to load indices
 */
export class LoadConfigurations {
  static readonly type = '[index-configuration] load configurations';

  /**
   * Force load indices
   *
   * @param force Indicator
   */
  constructor(public force: boolean = false) {}
}

/**
 * Trigger save index configuration
 */
export class SaveConfiguration {
  static readonly type = '[index-configuration] save configuration';

  constructor(public payload: IndexConfigurationModel) {}
}

/**
 * Trigger delete index configuration
 */
export class DeleteConfiguration {
  static readonly type = '[index-configuration] delete configuration';

  constructor(public id: string) {}
}
