<div class="content">
  <ag-grid-angular [columnDefs]="columnDefs" [defaultColDef]="gridColumnDefaults"
    [getContextMenuItems]="contextMenuItems" [gridOptions]="gridOptions" [rowData]="configurations$ | async"
    [sideBar]="gridSidebarDefs" class="ag-theme-engine" appGridStateSaver statePath="index.gridState">
  </ag-grid-angular>
  <div class="button-row">
    <div class="mat-spacer"></div>
    <button [permissionModule]="'index'" [permissionScope]="'WRITE'" appPermissionControl
      (click)="editConfigurationModal(null, false)" mat-stroked-button>Create</button>
  </div>
</div>