import { Injectable } from '@angular/core';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { utc } from 'dayjs';
import { iif, lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

import { IndexActionModel } from 'src/app/share/model/index-action.model';
import { ApiService } from 'src/app/share/service/api.service';
import { NotificationShowMessage } from 'src/app/state/notifications/notifications.actions';
import { SelectUser } from 'src/app/state/user/user.actions';

import { LoadData } from '../corporate-actions.actions';
import { OverviewParentState } from '../overview-parent/overview-parent.state';
import { DeleteSelectedActions, SelectActions, UpdateOrCreateAction } from './overview-index-actions.actions';
import { CorporateActionsIndexActionsStateModel } from './overview-index-actions.model';

/**
 * Helper function to generate unique node id
 *
 * @param data Index Action item
 */
export const getRowNodeId = (data: IndexActionModel): string => `${data.actionName}.${data.variationId}.${data.actionId}`;

/**
 * Index Action entity adapter
 */
const adapter = createEntityAdapter<IndexActionModel>({
  selectId: getRowNodeId,
});

@State<CorporateActionsIndexActionsStateModel>({
  name: 'caIndexActions',
  defaults: {
    loaded: false,
    loading: false,
    entities: adapter.getInitialState(),
    selected: [],
    gridState: null,
  },
})
@Injectable()
export class OverviewIndexActionsState {
  constructor(private api: ApiService, private store: Store) {}

  /**
   * Get entity by id
   *
   * @param id Entity id
   */
  static indexActionsById(id: string): (state: EntityState<IndexActionModel>) => IndexActionModel {
    return createSelector([OverviewIndexActionsState.entities], (state: EntityState<IndexActionModel>) => state.entities[id] || null);
  }

  @Selector([OverviewIndexActionsState])
  static entities(state: CorporateActionsIndexActionsStateModel): EntityState<IndexActionModel> {
    return state.entities;
  }

  @Selector([OverviewIndexActionsState])
  static selectedEntities(state: CorporateActionsIndexActionsStateModel): string[] {
    return state.selected;
  }

  @Selector([OverviewIndexActionsState.entities])
  static indexActions(state: EntityState<IndexActionModel>): IndexActionModel[] {
    return adapter.getSelectors().selectAll(state);
  }

  @Selector([OverviewIndexActionsState.selectedEntities])
  static hasSelectedItems(state: string[]): boolean {
    return state.length > 0;
  }

  /**
   * Reset loaded state. Needed to avoid reload actions, if corporate action pages not open or visited
   *
   * @param ctx State Context
   */
  @Action(SelectUser)
  selectedClientChanged(ctx: StateContext<CorporateActionsIndexActionsStateModel>): CorporateActionsIndexActionsStateModel {
    return ctx.patchState({
      loaded: false,
    });
  }

  /**
   * Load corporate action index actions and update store
   *
   * @param ctx State context
   * @param force Force reload flag
   */
  @Action(LoadData)
  async loadIndexActions(ctx: StateContext<CorporateActionsIndexActionsStateModel>, { force }: LoadData): Promise<void> {
    const { loaded, loading, entities } = ctx.getState();
    const { fromDate, toDate } = this.store.selectSnapshot(OverviewParentState.formModel);
    if ((!loaded || force) && !loading) {
      try {
        ctx.patchState({ loading: true });
        const result = await lastValueFrom(this.api.getIndexActions(fromDate, toDate));
        const newEntities = adapter.setAll(result, entities);
        ctx.patchState({
          entities: newEntities,
          loaded: true,
        });
      } catch (e) {
        this.store.dispatch(new NotificationShowMessage('error', 'Get corporate action index actions failed.', e));
      } finally {
        ctx.patchState({ loading: false });
      }
    }
  }

  /**
   * Handle grid selection changes
   *
   * @param ctx State context
   * @param payload List of all selected items
   */
  @Action(SelectActions)
  handleIndexActionSelectionChange(ctx: StateContext<CorporateActionsIndexActionsStateModel>, { payload }: SelectActions): void {
    ctx.patchState({
      selected: payload,
    });
  }

  /**
   * Handle delete selected index actions
   *
   * @param ctx StateContext
   */
  @Action(DeleteSelectedActions)
  async handleDeleteSelectedItems(ctx: StateContext<CorporateActionsIndexActionsStateModel>): Promise<void> {
    const { entities, selected } = ctx.getState();

    if (selected.length > 0) {
      const entitiesDict = adapter.getSelectors().selectEntities(entities);
      const requests = selected
        .map(id => entitiesDict[id])
        .filter(item => !!item)
        .map(item =>
          lastValueFrom(this.api.deleteIndexAction(item.actionId))
            .then(() => item)
            .catch(e => {
              this.store.dispatch(new NotificationShowMessage('error', `Failed to delete index action with ID ${item.actionId}`, e));
              return null;
            }),
        );

      const results = await Promise.all(requests).then(items => items.filter(item => item !== null));
      const deletedIds = results.map(getRowNodeId);
      const newEntities = adapter.removeMany(deletedIds, entities);
      const newSelectedEntities = selected.filter(id => !deletedIds.includes(id));
      ctx.patchState({
        entities: newEntities,
        selected: newSelectedEntities,
      });
    }
  }

  /**
   * Handle update or create index action
   *
   * @param ctx StateContext
   * @param payload Index Action to update or create
   * @param create Flag to create or update index action
   */
  @Action(UpdateOrCreateAction)
  async handleUpdateOrCreate(ctx: StateContext<CorporateActionsIndexActionsStateModel>, { payload, create }: UpdateOrCreateAction): Promise<void> {
    try {
      const data = await lastValueFrom(
        iif(() => create, this.api.createIndexAction(payload.indexId, payload.variationId, payload), this.api.updateIndexAction(payload.actionId, payload).pipe(map(item => [item]))),
      );
      const { entities, selected } = ctx.getState();
      const { fromDate, toDate } = this.store.selectSnapshot(OverviewParentState.formModel);
      let newEntities = adapter.upsertMany(data, entities);
      const toDelete = adapter
        .getSelectors()
        .selectAll(newEntities)
        .filter(item => !utc(item.effectiveDate, 'YYYY-MM-DD').isBetween(utc(fromDate), utc(toDate), 'day', '[]'))
        .map(getRowNodeId);
      newEntities = adapter.removeMany(toDelete, newEntities);
      const newSelectedEntities = selected.filter(id => !toDelete.includes(id));
      ctx.patchState({
        selected: newSelectedEntities,
        entities: newEntities,
      });
    } catch (e) {
      const message = create ? 'Failed to create new index action.' : 'Failed to update index action.';
      this.store.dispatch(new NotificationShowMessage('error', message, e));
    }
  }
}
