<ag-grid-angular [columnDefs]="columnDefs"
                 [defaultColDef]="gridColumnDefaults"
                 [getContextMenuItems]="contextMenuItems"
                 [gridOptions]="gridOptions"
                 [rowData]="variationRestatements$ | async"
                 [sideBar]="gridSidebarDefs"
                 class="grid-content ag-theme-engine"
                 appGridStateSaver 
                 statePath="indexActions.gridState">
</ag-grid-angular>
