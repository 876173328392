/**
 * Trigger action to Query providers
 */
export class LoadProviderQueries {
  static readonly type = '[Query Providers] load provider queries';

  /**
   * Force load providers
   *
   * @param force Indicator
   */
  constructor(public force: boolean = false) {}
}
