import { ColDef, GridOptions, ICellRendererParams, MenuItemDef, SideBarDef } from '@ag-grid-community/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';
import { ResetGridState } from 'src/app/core/grid-state-saver/grid-state-saver.actions';
import { ConfirmModalComponent } from '../../share/components/confirm-modal/confirm-modal.component';
import { TableCellButtonComponent } from '../../share/components/table-cell-button/table-cell-button.component';
import { IndexConfigurationModel } from '../../share/model/index-configuration.model';
import { DeleteConfiguration, LoadConfigurations, SaveConfiguration } from '../../state/manage-index-configuration/manage-index-configuration.action';
import { ManageIndexConfigurationState } from '../../state/manage-index-configuration/manage-index-configuration.state';
import { UserState } from '../../state/user/user.state';
import { ManageIndexConfigurationDetailModalComponent } from '../manage-index-configuration-detail-modal/manage-index-configuration-detail-modal.component';

@Component({
  selector: 'app-manage-index-configuration',
  templateUrl: './manage-index-configuration.component.html',
  styleUrls: ['./manage-index-configuration.component.scss'],
})
export class ManageIndexConfigurationComponent implements OnInit, OnDestroy {
  @Select(ManageIndexConfigurationState.configurations) configurations$: Observable<IndexConfigurationModel[]>;

  @Select(UserState.clientIdSelected) selectedClient$: Observable<number | string>;

  destroy$ = new Subject<void>();

  gridOptions: GridOptions = {
    animateRows: false,
    pagination: true,
    getRowId: params => ManageIndexConfigurationState.getRowNodeId(params.data),
    components: {
      tbButton: TableCellButtonComponent,
    },
  };

  // default column options
  gridColumnDefaults: ColDef = {
    filter: 'agMultiColumnFilter',
    sortable: true,
    resizable: true,
    menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
  };

  // sidebar configuration
  gridSidebarDefs: SideBarDef = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressPivotMode: true,
          suppressPivots: true,
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
      {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel',
      },
    ],
  };

  columnDefs = [
    {
      field: 'configId',
      headerName: 'Config ID',
      width: 125,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 300,
    },
    {
      field: 'masterdataId',
      headerName: 'Master ID',
      width: 125,
    },
    {
      field: 'master',
      headerName: 'Master',
      width: 300,
    },
    {
      cellRenderer: 'tbButton',
      width: 125,
      suppressMenu: true,
      filter: false,
      sortable: false,
      resizable: false,
      suppressColumnsToolPanel: true,
      cellClass: 'fill-content disable-highlight',
      cellRendererParams: () => {
        return {
          buttonName: 'Edit',
          permissionModule: 'corporate_actions',
          permissionScope: 'WRITE',
          onClick: (event: MouseEvent, params: ICellRendererParams) => {
            this.editConfigurationModal(params.node.id);
          },
        };
      },
    },
    {
      cellRenderer: 'tbButton',
      width: 125,
      suppressMenu: true,
      filter: false,
      sortable: false,
      resizable: false,
      suppressColumnsToolPanel: true,
      cellClass: 'fill-content disable-highlight',
      cellRendererParams: {
        buttonName: 'Delete',
        permissionModule: 'corporate_actions',
        permissionScope: 'WRITE',
        color: 'warn',
        onClick: (_event: MouseEvent, params: ICellRendererParams) => {
          this.deleteConfiguration(params.node.id);
        },
      },
    },
  ];

  constructor(private store: Store, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.contextMenuItems = this.contextMenuItems.bind(this);
    this.store.dispatch(new LoadConfigurations());
    this.selectedClient$.pipe(skip(1), takeUntil(this.destroy$)).subscribe(() => this.store.dispatch(new LoadConfigurations(true)));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Display modal to edit configurations
   *
   * @param configId existing config to edit
   * @param edit Flag if a new config should be created
   */
  editConfigurationModal(configId: string, edit = true): void {
    this.dialog
      .open(ManageIndexConfigurationDetailModalComponent, {
        width: '600px',
        data: {
          configId,
          edit,
        },
      })
      .afterClosed()
      .subscribe((data: IndexConfigurationModel) => {
        if (data) {
          this.store.dispatch(new SaveConfiguration(data));
        }
      });
  }

  /**
   * Display modal to delete configuration
   *
   * @param configId Existing configuration to delete
   */
  deleteConfiguration(configId: string): void {
    const configuration = this.store.selectSnapshot(ManageIndexConfigurationState.getConfigById(configId));
    this.dialog
      .open(ConfirmModalComponent, {
        width: '600px',
        data: {
          configId,
          title: 'Delete Configuration',
          text: `Do you really want to delete configuration ${configuration?.name} ?`,
          buttons: [
            { text: 'Cancel', type: 'cancel' },
            { text: 'Delete', type: 'submit', color: 'warn' },
          ],
        },
      })
      .afterClosed()
      .subscribe(data => {
        if (data === 'submit') {
          this.store.dispatch(new DeleteConfiguration(configId));
        }
      });
  }

  /**
   * Context menu item generator
   */
  contextMenuItems(): (MenuItemDef | string)[] {
    const resetState = {
      name: 'Reset Grid',
      icon: 'refresh',
      action: () => this.store.dispatch(new ResetGridState('index.gridState')),
    };
    return ['copy', 'export', resetState];
  }
}
