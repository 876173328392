<form #form="ngForm" (ngSubmit)="saveSecurityListing(form.value)"
      *ngLet="securityListingIdChanged$ | async as listingId">
  <h1 mat-dialog-title>Security Listing</h1>
  <mat-dialog-content class="content-wrapper">
    <mat-form-field>
      <mat-label>Listing</mat-label>
      <mat-select (selectionChange)="listingChanged($event)" [ngModel]="listingId" name="listingId">
        <mat-option [value]="-1" [disabled]="'securities' | permissioncontrol: 'WRITE'">Create New Listing</mat-option>
        <mat-option *ngFor="let option of securityListing$ | async" [value]="option.listingId">
          {{option.stockExchange}} - {{option.currency}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="listingId > -1">
      <mat-label>Listing ID</mat-label>
      <input [ngModelOptions]="{standalone: true}" [ngModel]="listingId" disabled id="listingId" matInput/>
    </mat-form-field>

    <mat-form-field *ngIf="listingId > -1">
      <mat-label>Security ID</mat-label>
      <input [ngModelOptions]="{standalone: true}" [ngModel]="securityIdChanged$ | async" disabled id="securityId"
             matInput/>
    </mat-form-field>

    <!--Show for both create and edit-->
    <mat-form-field>
      <mat-label>Stock Exchange</mat-label>
      <mat-select [ngModel]="(selectedListing$ | async)?.stockExchangeId" name="stockExchangeId" required>
        <mat-option *ngFor="let option of stockExchanges$ | async" [value]="option.stockExchangeId">
          {{option.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Currency</mat-label>
      <mat-select [ngModel]="(selectedListing$ | async)?.currency"name="currency" required>
        <mat-option *ngFor="let currency of currencies$ | async"
                    [value]="currency.iso4217">{{currency.iso4217}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>SEDOL</mat-label>
      <input [ngModel]="(selectedListing$ | async)?.sedol" matInput name="sedol"/>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Ticker</mat-label>
      <input [ngModel]="(selectedListing$ | async)?.exchangeTicker" matInput name="exchangeTicker"/>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Reuters RIC</mat-label>
      <input [ngModel]="(selectedListing$ | async)?.ric" matInput name="ric"/>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Morningstar Symbol</mat-label>
      <input [ngModel]="(selectedListing$ | async)?.morningStarSymbol" matInput name="morningStarSymbol"/>
    </mat-form-field>

  </mat-dialog-content>
  <div *ngLet="securityListing$ | async as listings" class="modal-buttons" mat-dialog-actions>
    <button [disabled]="form.invalid || ('securities' | permissioncontrol: 'WRITE')"
            [permissionModule]="'securities'"
            [permissionScope]="'WRITE'"
            appPermissionControl
            color="primary"
            mat-button
            type="submit">Save
    </button>
    <button (click)="deleteSecurityListing(listingId, listings)"
            [disabled]="listingId === -1"
            [permissionModule]="'securities'"
            [permissionScope]="'WRITE'"
            appPermissionControl
            mat-button
            type="button">Delete
    </button>
    <button [mat-dialog-close]="null" mat-button>Close</button>
  </div>
</form>
