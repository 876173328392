import { NgModule } from '@angular/core';
import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { NotifierModule } from 'angular-notifier';
import { ClipboardModule } from 'ngx-clipboard';
import { FileSaverModule } from 'ngx-filesaver';
import { SocketIoModule } from 'ngx-socket-io';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';

import { OverviewIndexActionsState } from 'src/app/corporate-actions/state/overview-index-actions/overview-index-actions.state';
import { OverviewParentState } from 'src/app/corporate-actions/state/overview-parent/overview-parent.state';
import { environment } from '../../environments/environment';
import { CurrencyState } from './currency/currency.state';
import { GlobalState } from './global/global.state';
import { ManageIndexConfigurationState } from './manage-index-configuration/manage-index-configuration.state';
import { MasterIndexState } from './master-index/master-index.state';
import { MetaState } from './meta/meta.state';
import { NotificationsState } from './notifications/notifications.state';
import { ProviderQueryState } from './provider-query/provider-query.state';
import { SecuritiesState } from './securities/securities.state';
import { StockExchangeState } from './stock-exchange/stock-exchange.state';
import { UserState } from './user/user.state';
import { VariationActionsState } from './variation-actions/variation-actions.state';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

@NgModule({
  declarations: [],
  imports: [
    ClipboardModule,
    FileSaverModule,
    SocketIoModule,
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
    }),
    NgxsModule.forRoot(
      [
        CurrencyState,
        OverviewIndexActionsState,
        OverviewParentState,
        GlobalState,
        MasterIndexState,
        ManageIndexConfigurationState,
        MetaState,
        NotificationsState,
        ProviderQueryState,
        SecuritiesState,
        StockExchangeState,
        UserState,
        VariationActionsState,
      ],
      {
        developmentMode: !environment.production,
        selectorOptions: {
          // These Selector Settings are recommended in preparation for NGXS v4
          // (See above for their effects)
          injectContainerState: false,
          suppressErrors: false,
        },
      },
    ),
    NgxsFormPluginModule.forRoot(),
    NgxsResetPluginModule.forRoot(),
    NgxsSelectSnapshotModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      key: [
        'indexLauncherProperties.editBy',
        'indexLauncherImporter',
        'corporateactions.customCheckForm',
        'corporateactions.masterCheckForm',
        'notifications.level',
        'user.selectedClient',
        'global.sideNavLocked',
        'global.sideNavOpen',
        'global.selectedWorldClock',
        'prices.gridState',
        'caIndexActions.gridState',
        'caOverviewOverrides.gridState',
        'caOverviewProviderCustom.gridState',
        'caOverviewProviderMaster.gridState',
        'calendar.gridState',
        'monitoringoverview.gridState',
        'securities.gridState',
        'rtDistribution.gridState',
        'indexLauncher.gridState',
        'index.gridState',
        'indexActions.gridState',
        'executor',
      ],
      beforeSerialize: (obj, key) => {
        // Remove executionTimerId from the executor state, not needed to persistent
        if (key === 'executor') {
          const executions = {};
          for (const key in obj.executions) {
            const entities = {};
            for (const selectionKey in obj.executions[key].entities) {
              entities[selectionKey] = {
                ...obj.executions[key].entities[selectionKey],
                timer: {
                  ...obj.executions[key].entities[selectionKey].timer,
                  executionTimerId: undefined,
                },
              };
            }
            executions[key] = {
              ...obj.executions[key],
              entities,
            };
          }
          return { ...obj, executions };
        }
        return obj;
      },
    }),
    NotifierModule.withConfig({
      behaviour: {
        autoHide: 3000,
      },
      position: {
        horizontal: {
          position: 'right',
          distance: 16,
        },
        vertical: {
          position: 'top',
          distance: 70,
        },
      },
    }),
  ],
  exports: [NgxsFormPluginModule, NotifierModule],
})
export class StateModule {}
