<mat-drawer-container [ngClass]="{'locked': sideNavLocked$ | async}">
  <mat-drawer
    (closed)="dispatchToggleNavbarOpen()"
    [mode]="sideNavMode$ | async"
    [opened]="sideNavOpen$ | async"
    class="mat-elevation-z1">
    <mat-toolbar>
      <mat-toolbar-row class="drawer-header">
        <button (click)="dispatchToggleLockNavbar()" *ngIf="(deviceType$ | async) !== 'mobile'" mat-icon-button>
          <mat-icon>{{(sideNavLocked$ | async) ? 'lock' : 'lock_open'}}</mat-icon>
        </button>
        <div class="drawer-spacer"></div>
      </mat-toolbar-row>
    </mat-toolbar>
    <mat-nav-list>
      <mat-list-item *ngFor="let item of navListItems$ | async" [routerLink]="item.path" routerLinkActive="active">
        <mat-icon *ngIf="item.icon" matListIcon>{{item.icon}}</mat-icon>
        <h3 matLine>{{item.title}}</h3>
      </mat-list-item>
    </mat-nav-list>
    <div class="drawer-spacer"></div>
    <mat-nav-list>
      <mat-divider></mat-divider>
      <mat-list-item (click)="dispatchUserLogout()">
        <mat-icon matListIcon>logout</mat-icon>
        <h3 matLine>Logout</h3>
      </mat-list-item>
    </mat-nav-list>
  </mat-drawer>
  <mat-drawer-content>
    <mat-toolbar>
      <mat-toolbar-row class="mat-elevation-z1">
        <button (click)="dispatchToggleNavbarOpen()" *ngIf="(deviceType$ | async) === 'mobile'" mat-icon-button>
          <mat-icon>menu</mat-icon>
        </button>
        <button [matMenuTriggerFor]="clientMenu" mat-stroked-button>
          {{(selectedClient$ | async) === null ? 'No Clients' : (selectedClient$ | async)?.name | uppercase}}
        </button>
        <mat-menu #clientMenu="matMenu" xPosition="after">
          <button (click)="dispatchSelectUser(client.clientId)" *ngFor="let client of clients$ | async"
                  mat-menu-item>{{client.name | uppercase}}
          </button>
        </mat-menu>
        <div class="drawer-spacer">
          <div class="center-logo">
            <img src="assets/logo.svg" />
          </div>
        </div>
        <button [matMenuTriggerFor]="clockMenu" mat-stroked-button>
          <div class="world-clock-selector">
            <mat-icon>expand_more</mat-icon>
            <app-world-clock [label]="(selectedClockItem$ | async).name"
                             [timezone]="(selectedClockItem$ | async).timezone"
                             format="HH:mm:ss"></app-world-clock>
          </div>
        </button>
        <mat-menu #clockMenu>
          <button (click)="dispatchSelectClockItem(clock.name)" *ngFor="let clock of clockItems$ | async" mat-menu-item>
            <app-world-clock [label]="clock.name"
                             [timezone]="clock.timezone"
                             format="HH:mm:ss"></app-world-clock>
          </button>
        </mat-menu>
        <button (click)="dispatchResetUnreadCount()"
                *ngLet="notificationCount$ | async as notificationCount"
                [matMenuTriggerFor]="notification"
                mat-icon-button>
          <mat-icon [matBadgeHidden]="notificationCount === 0"
                    [matBadge]="notificationCount > 10 ? '10+' : notificationCount"
                    matBadgeColor="warn"
                    matBadgeSize="small">
            {{notificationCount > 0 ? 'notifications_active' : 'notifications'}}
          </mat-icon>
        </button>
        <mat-menu #notification class="notification-panel">
          <mat-button-toggle-group (change)="dispatchNotificationFilterChange($event)"
                                   [value]="notificationLevel$ | async">
            <mat-button-toggle (click)="$event.stopPropagation();" *ngFor="let notification of notificationLevels"
                               [value]="notification.level"
                               aria-label="Notification level">
              <mat-icon [ngClass]="notification.level">{{notification.icon}}</mat-icon>
            </mat-button-toggle>
          </mat-button-toggle-group>
          <mat-list class="notifications">
            <button (click)="dispatchCopyToClipboard($event, item)"
                    *ngFor="let item of notifications$ | async | slice:0:9"
                    [ngClass]="item.level"
                    class="navigation-item"
                    mat-menu-item>
              {{item.message}}
            </button>
            <button class="show-all" disabled mat-menu-item>Show all</button>
          </mat-list>
        </mat-menu>
      </mat-toolbar-row>
    </mat-toolbar>
    <notifier-container></notifier-container>
    <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>
