import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteGuard } from './core/permission-control/route.guard';

import { DashboardComponent } from './dashboard/dashboard.component';
import { FallbackComponent } from './fallback/fallback.component';
import { ManageIndexComponent, ManageIndexRoutes } from './index-administration';
import { LoginComponent } from './login/login.component';
import { SecuritiesComponent } from './securities/securities.component';

import { AuthGuard } from './share/guard/auth.guard';

const routes: Routes = [
  {
    component: LoginComponent,
    path: 'login',
  },
  {
    component: DashboardComponent,
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'manage-indices',
        component: ManageIndexComponent,
        children: ManageIndexRoutes.childRoutes,
        canActivate: [RouteGuard],
        runGuardsAndResolvers: 'always',
        data: { module: 'manage_indices', neededPermission: 'READ' },
      },
      {
        path: 'index-launcher',
        loadChildren: () => import('./index-launcher/index-launcher.module').then(m => m.IndexLauncherModule),
        canActivate: [RouteGuard],
        runGuardsAndResolvers: 'always',
        data: { module: 'index', neededPermission: 'READ' },
      },
      {
        path: 'securities',
        component: SecuritiesComponent,
        canActivate: [RouteGuard],
        runGuardsAndResolvers: 'always',
        data: { module: 'securities', neededPermission: 'READ' },
      },
      {
        path: 'realtime-distribution',
        loadChildren: () => import('./realtime-distribution/realtime-distribution.module').then(m => m.RealtimeDistributionModule),
        canActivate: [RouteGuard],
        runGuardsAndResolvers: 'always',
        data: { module: 'distribution', neededPermission: 'READ' },
      },
      {
        path: 'corporate-actions',
        loadChildren: () => import('./corporate-actions/corporate-actions.module').then(m => m.CorporateActionsModule),
        canActivate: [RouteGuard],
        runGuardsAndResolvers: 'always',
        data: { module: 'corporate_actions', neededPermission: 'READ' },
      },
      {
        path: 'prices',
        loadChildren: () => import('./prices/prices.module').then(m => m.PricesModule),
        canActivate: [RouteGuard],
        runGuardsAndResolvers: 'always',
        data: { module: 'securities', neededPermission: 'READ' },
      },
      {
        path: 'calendar',
        loadChildren: () => import('src/app/calendar/calendar.module').then(m => m.CalendarModule),
        canActivate: [RouteGuard],
        runGuardsAndResolvers: 'always',
        data: { module: 'calendar', neededPermission: 'READ' },
      },
      {
        path: 'executor',
        loadChildren: () => import('src/app/executor/executor.module').then(m => m.ExecutorModule),
        canActivate: [RouteGuard],
        runGuardsAndResolvers: 'always',
        data: { module: 'sql_executor', neededPermission: 'READ' },
      },
      {
        path: 'country-characteristics',
        loadChildren: () => import('src/app/country-characteristics/country-characteristics.module').then(m => m.CountryCharacteristicsModule),
        canActivate: [RouteGuard],
        runGuardsAndResolvers: 'always',
        data: { module: 'input_data', neededPermission: 'READ' },
      },
      {
        path: 'monitoring',
        loadChildren: () => import('src/app/monitoring/monitoring.module').then(m => m.MonitoringModule),
        canActivate: [RouteGuard],
        runGuardsAndResolvers: 'always',
        data: { module: 'index_calculation', neededPermission: 'READ' },
      },
      {
        path: 'fallback',
        component: FallbackComponent,
      },
      {
        redirectTo: 'monitoring',
        path: '**',
      },
    ],
  },
  {
    redirectTo: 'login',
    path: '**',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
