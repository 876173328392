import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpCancelService {
  private pendingHttpRequests$ = new Subject<void>();

  /**
   * Cancel pending requests
   */
  public cancelPendingRequests() {
    this.pendingHttpRequests$.next();
  }

  /**
   * Cancel pending requests observer
   */
  public onCancelPendingRequests() {
    return this.pendingHttpRequests$.asObservable();
  }
}
