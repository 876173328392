import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { TooltipPosition } from '@angular/material/tooltip';
import { ComponentStoreService } from '../../../core/component-store/component-store.service';

export interface IButtonParams extends ICellRendererParams {
  color?: ThemePalette;
  icon?: string;
  buttonName: string;
  tooltip?: string;
  tooltipPosition?: TooltipPosition;
  onClick: (event?: Event, params?: IButtonParams, fileList?: FileList) => void;
  disabled: boolean;
  type?: 'button' | 'file';
  accept?: string;
  multiple?: boolean;
  permissionModule?: string;
  permissionScope?: string;
}

interface ButtonState {
  params?: IButtonParams;
  config: {
    disabled: boolean;
    type?: 'button' | 'file';
    accept?: string;
    multiple?: boolean;
    permissionModule?: string;
    permissionScope?: string;
    color?: ThemePalette;
    icon?: string;
    buttonName: string;
  };
}

@Component({
  selector: 'app-table-button',
  templateUrl: './table-cell-button.component.html',
  styleUrls: ['./table-cell-button.component.scss'],
})
export class TableCellButtonComponent extends ComponentStoreService<ButtonState> implements ICellRendererAngularComp {
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

  config$ = this.select(state => state.config);

  constructor() {
    super({
      params: null,
      config: {
        disabled: false,
        type: 'button',
        accept: undefined,
        multiple: false,
        permissionModule: null,
        permissionScope: 'NONE',
        color: undefined,
        icon: null,
        buttonName: undefined,
      },
    });
  }

  agInit(params: IButtonParams): void {
    this.updateParams(params);
  }

  refresh(params: IButtonParams): boolean {
    this.updateParams(params);
    return false;
  }

  /**
   * Update cell params
   *
   * @param params Params
   */
  updateParams(params: IButtonParams): void {
    this.setState({
      params,
      config: {
        icon: params.icon,
        buttonName: params.buttonName,
        color: params.color,
        disabled: params.disabled || false,
        type: params.type || 'button',
        accept: params.accept || null,
        multiple: params.multiple || false,
        permissionModule: params.permissionModule || null,
        permissionScope: params.permissionScope || null,
      },
    });
  }

  /**
   * Handle button click event
   *
   * @param event Button click event
   */
  buttonClick(event: Event): void {
    const { params, config } = this.getSnapshot();
    if (config.type === 'button') {
      params?.onClick(event, params);
    } else if (config.type === 'file') {
      this.fileInput.nativeElement.click();
    }
  }

  /**
   * Handle file event change
   *
   * @param event File change event
   */
  handleFileChange(event: Event): void {
    const { params } = this.getSnapshot();
    const target = event.target as HTMLInputElement;
    const files = target.files;
    params?.onClick(event, params, files);
    this.fileInput.nativeElement.value = null;
  }
}
