<div class="content-wrapper">
  <mat-card>
    <nav mat-tab-nav-bar>
      <a #rla="routerLinkActive"
         *ngFor="let routes of routerLinks"
         [active]="rla.isActive"
         [routerLinkActiveOptions]="{exact: true}"
         [routerLink]="[routes.path]"
         mat-tab-link
         routerLinkActive>{{routes.label}}
      </a>
    </nav>
    <router-outlet></router-outlet>
  </mat-card>
</div>
