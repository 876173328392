import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { RevalidationProblematicSecurities, RevalidationResult } from '../../share/model/revalidation-result.model';
import { VariationActionsState } from '../../state/variation-actions/variation-actions.state';

@Component({
  selector: 'app-manage-index-actions-validation-modal',
  templateUrl: './manage-index-actions-validation-modal.component.html',
  styleUrls: ['./manage-index-actions-validation-modal.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ManageIndexActionsValidationModalComponent {
  @Select(VariationActionsState.loadRevalidationResult) isLoading: Observable<boolean>;

  @Select(VariationActionsState.loadRevalidationResultError) isLoadingError: Observable<boolean>;

  @Select(VariationActionsState.latestRevalidationResult) result: Observable<RevalidationResult[]>;

  columnsToDisplay = ['expanded', 'isin', 'name'];

  expandedElement: RevalidationProblematicSecurities = null;
}
