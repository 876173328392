import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { forkJoin, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { IndexConfigurationModel } from '../../share/model/index-configuration.model';
import { ApiService } from '../../share/service/api.service';
import { EntityModel } from '../index';
import { NotificationShowMessage } from '../notifications/notifications.actions';
import { SelectUser } from '../user/user.actions';
import { addOne, entityMapper, getInitialState, removeOne, updateOne } from '../utils/entity-mapper';
import { VariationActionsStateModel } from '../variation-actions/variation-actions.model';
import { DeleteConfiguration, LoadConfigurations, SaveConfiguration } from './manage-index-configuration.action';
import { ManageIndexConfigurationStateModel } from './manage-index-configuration.model';

@State<ManageIndexConfigurationStateModel>({
  name: 'index',
  defaults: {
    loaded: false,
    configurationEntities: getInitialState(),
    gridState: null,
  },
})
@Injectable()
export class ManageIndexConfigurationState {
  constructor(public api: ApiService, public store: Store) {}

  /**
   * Get config by id
   *
   * @param id Unique ID
   */
  static getConfigById(id: string | number) {
    return createSelector([ManageIndexConfigurationState.configEntities], (state: EntityModel<IndexConfigurationModel>): IndexConfigurationModel => state.entities[id]);
  }

  /**
   * Generate node id for indices
   *
   * @param item Model
   */
  static getRowNodeId(item: IndexConfigurationModel): string {
    return `${item.masterdataId}.${item.configId}`;
  }

  @Selector([ManageIndexConfigurationState])
  static configEntities(state: ManageIndexConfigurationStateModel): EntityModel<IndexConfigurationModel> {
    return state.configurationEntities;
  }

  @Selector([ManageIndexConfigurationState.configEntities])
  static configurations(entities: EntityModel<IndexConfigurationModel>): IndexConfigurationModel[] {
    return entities.ids.map(id => entities.entities[id]);
  }

  /**
   * Action to load indices
   *
   * @param ctx State context
   * @param force Payload to force load indices
   */
  @Action(LoadConfigurations)
  loadConfigurations(ctx: StateContext<ManageIndexConfigurationStateModel>, { force }: LoadConfigurations): Observable<ManageIndexConfigurationStateModel> {
    const { loaded } = ctx.getState();
    if (force || !loaded) {
      return forkJoin([this.api.loadConfigurations()]).pipe(
        map(([configurations]) => {
          const indexEntities = entityMapper(configurations, ManageIndexConfigurationState.getRowNodeId);
          return ctx.patchState({
            configurationEntities: indexEntities,
            loaded: true,
          });
        }),
      );
    }
    return of(ctx.getState());
  }

  /**
   * Action to create or save index configurations
   *
   * @param ctx State context
   * @param payload Configuration to create or save
   */
  @Action(SaveConfiguration)
  saveConfiguration(ctx: StateContext<ManageIndexConfigurationStateModel>, { payload }: SaveConfiguration): Observable<ManageIndexConfigurationStateModel> {
    if (payload.configId) {
      const { configId, masterdataId } = payload;
      return this.api.updateConfiguration(masterdataId, configId, payload).pipe(
        map(result => {
          const { configurationEntities } = ctx.getState();
          const id = ManageIndexConfigurationState.getRowNodeId(result);
          const newConfigurationEntities = updateOne(configurationEntities, id, result);
          return ctx.patchState({
            configurationEntities: newConfigurationEntities,
          });
        }),
        tap(() => this.store.dispatch(new NotificationShowMessage('success', 'Configuration updated.'))),
      );
    } else {
      const { masterdataId } = payload;
      return this.api.createConfiguration(masterdataId, payload).pipe(
        map(result => {
          const { configurationEntities } = ctx.getState();
          const id = ManageIndexConfigurationState.getRowNodeId(result);
          const newConfigurationEntities = addOne(configurationEntities, id, result);
          return ctx.patchState({
            configurationEntities: newConfigurationEntities,
          });
        }),
        tap(() => this.store.dispatch(new NotificationShowMessage('success', 'New configuration created.'))),
      );
    }
  }

  /**
   * Action to delete configuration
   *
   * @param ctx State Context
   * @param id Node Id
   */
  @Action(DeleteConfiguration)
  deleteConfiguration(ctx: StateContext<ManageIndexConfigurationStateModel>, { id }: DeleteConfiguration): Observable<ManageIndexConfigurationStateModel> {
    const item = this.store.selectSnapshot(ManageIndexConfigurationState.getConfigById(id));
    if (item) {
      return this.api.deleteConfiguration(item.masterdataId, item.configId).pipe(
        map(() => {
          const { configurationEntities } = ctx.getState();
          const newConfigurationEntities = removeOne(configurationEntities, id);
          return ctx.patchState({
            configurationEntities: newConfigurationEntities,
          });
        }),
      );
    }
    return of(ctx.getState());
  }

  /**
   * Reset loaded flag on client change
   *
   * @param ctx State context
   */
  @Action(SelectUser)
  selectedClientChanged(ctx: StateContext<VariationActionsStateModel>): VariationActionsStateModel {
    return ctx.patchState({
      loaded: false,
    });
  }
}
