import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { StateResetAll } from 'ngxs-reset-plugin';
import { lastValueFrom, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PermissionMap } from 'src/app/share/model/permission.model';
import { ApiService } from '../../share/service/api.service';
import { AuthService } from '../../share/service/auth.service';
import { LoadPermissionMap, Login, LoginSuccess, Logout, SelectedUserChanged, SelectUser } from './user.actions';
import { Client, UserStateModel } from './user.model';

@State<UserStateModel>({
  name: 'user',
  defaults: {
    loading: false,
    logo: 'assets/default_logo.png',
    token: null,
    userName: null,
    selectedClient: null,
    clients: [],
    clientIds: [],
    permissionMap: {},
  },
})
@Injectable()
export class UserState {
  constructor(private authService: AuthService, private apiService: ApiService, private store: Store) {}

  @Selector([UserState])
  static userAuthToken(state: UserStateModel): string {
    return state.token;
  }

  @Selector([UserState])
  static userName(state: UserStateModel): string {
    return state.userName;
  }

  @Selector([UserState])
  static logo(state: UserStateModel): string {
    return state.logo;
  }

  @Selector([UserState])
  static clientIdSelected(state: UserStateModel): string | number | null {
    const { selectedClient, clientIds } = state;
    const containsId = clientIds?.find(item => Number(item) === Number(selectedClient));
    if (!containsId && clientIds?.length > 0) {
      return state.clientIds[0];
    }
    return state.selectedClient;
  }

  @Selector([UserState])
  static clients(state: UserStateModel): Client[] {
    return state.clients;
  }

  @Selector([UserState])
  static permissionMap(state: UserStateModel): { [key: string]: string[] } {
    return state.permissionMap;
  }

  @Selector([UserState.userAuthToken])
  static isAuthenticated(token: string): boolean {
    return !!token;
  }

  @Selector([UserState.isAuthenticated, UserState.clients, UserState.permissionMap])
  static permissionLoaded(authenticated: string, clients: Client[], permissionMap: PermissionMap): boolean {
    return authenticated && clients.length && !!permissionMap;
  }

  @Selector([UserState.clients, UserState.clientIdSelected])
  static selectedClient(clients: Client[], id: string | number | null): Client | null {
    const countClients = clients?.length || 0;
    if (countClients === 0) {
      return null;
    } else if (id === null) {
      return clients[0];
    } else {
      return clients.find(item => item.clientId === id);
    }
  }

  static checkUserRole(module: string) {
    return createSelector(
      [UserState.selectedClient, UserState.permissionMap],
      (client: Client | null, permissionMap: PermissionMap) => permissionMap[client?.role]?.filter(permission => permission.includes(module)) || [],
    );
  }

  @Action(Login)
  async login(ctx: StateContext<UserStateModel>, { payload }: Login): Promise<void> {
    const { loading } = ctx.getState();
    const { token, clients, userName } = payload;

    if (!loading) {
      const clientMap = clients?.reduce((acc, client) => acc.set(client.clientId, client), new Map<string | number, Client>());
      const clientIds = [...clientMap.keys()];
      ctx.patchState({ token, clientIds, userName, loading: true });
      try {
        const mappedItems = await lastValueFrom(
          this.apiService.findClients(clientIds).pipe(
            map(items =>
              items.map(item => {
                return {
                  ...item,
                  role: clientMap.get(item.clientId)?.role,
                };
              }),
            ),
          ),
        );

        if (mappedItems) {
          ctx.patchState({ clients: mappedItems });
          this.store.dispatch(new LoginSuccess());
        } else {
          ctx.patchState({ token: null, clientIds: [], userName: null });
        }
      } catch (e) {
        ctx.patchState({ token: null, clientIds: [], userName: null });
      } finally {
        ctx.patchState({ loading: false });
      }
    }
  }

  @Action(Logout)
  logout(): void {
    this.store.dispatch(new StateResetAll());
    this.authService.logout();
  }

  @Action(SelectUser)
  selectUser(ctx: StateContext<UserStateModel>, { selectedClient }: SelectUser): UserStateModel {
    const state = ctx.patchState({
      selectedClient,
    });
    this.store.dispatch(new SelectedUserChanged());
    return state;
  }

  @Action(LoadPermissionMap)
  loadPermissionMap(ctx: StateContext<UserStateModel>): Observable<UserStateModel> {
    return this.apiService.permissionMap().pipe(
      map(permissions => ctx.patchState({ permissionMap: permissions })),
      catchError(() => of(ctx.patchState({ permissionMap: {} }))),
    );
  }
}
