import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate } from '@angular/service-worker';
import { EMPTY, filter, from, iif, interval, Subject, takeUntil } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UpdateService implements OnDestroy {
  destroy$ = new Subject<void>();

  constructor(public swUpdate: SwUpdate, public snackbar: MatSnackBar) {
    iif(
      () => swUpdate.isEnabled,
      interval(environment.updateInterval * 60 * 60 * 1000).pipe(
        startWith(0),
        switchMap(() => from(swUpdate.checkForUpdate())),
        filter(updateFound => updateFound),
      ),
      EMPTY,
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.openUpdateSnackBar());
  }

  /**
   * Trigger destroy subject and unbind resources to avoid memory leaks
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Show new version notification
   */
  openUpdateSnackBar() {
    this.snackbar
      .open('A new version is available. Update to reload the page.', 'Update', {
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        duration: 60 * 60 * 1000,
      })
      .onAction()
      .subscribe(() => {
        location.reload();
      });
  }
}
