import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, combineLatest, defer, EMPTY, iif, lastValueFrom, Observable, of, Subject, switchMap, takeUntil } from 'rxjs';
import { first, map, share } from 'rxjs/operators';
import { ConfirmModalComponent } from '../../share/components/confirm-modal/confirm-modal.component';
import { CurrencyModel } from '../../share/model/currency.model';
import { SecurityListing } from '../../share/model/security.model';
import { StockExchangeModel } from '../../share/model/stock-exchange.model';
import { ApiService } from '../../share/service/api.service';
import { LoadCurrencies } from '../../state/currency/currency.actions';
import { CurrencyState } from '../../state/currency/currency.state';
import { NotificationShowMessage } from '../../state/notifications/notifications.actions';
import { SecuritiesState } from '../../state/securities/securities.state';
import { LoadStockExchanges } from '../../state/stock-exchange/stock-exchange.actions';
import { StockExchangeState } from '../../state/stock-exchange/stock-exchange.state';

class EditSecurityListingData {
  securityId: string;
}

interface FormModel {
  listingId: number;
  stockExchangeId: number;
  currency: string;
  sedol?: string;
  exchangeTicker?: string;
  ric?: string;
  morningStarSymbol?: string;
}

@Component({
  selector: 'app-security-listing-modal',
  templateUrl: './security-listing-modal.component.html',
  styleUrls: ['./security-listing-modal.component.scss'],
})
export class SecurityListingModalComponent implements OnInit, OnDestroy {
  @Select(StockExchangeState.stockExchanges) stockExchanges$: Observable<StockExchangeModel[]>;

  @Select(CurrencyState.currencies) currencies$: Observable<CurrencyModel[]>;

  securityIdChanged$ = new BehaviorSubject<string>(null);

  securityListingIdChanged$ = new BehaviorSubject<number>(-1);

  security$ = this.securityIdChanged$.pipe(map(id => (id ? this.store.selectSnapshot(SecuritiesState.getSecurityById(id)) : null)));

  securityListing$ = this.security$.pipe(
    switchMap(security => iif(() => !!security, this.api.getListingForSecurity(security.securityId), of<SecurityListing[]>([]))),
    share(),
  );

  selectedListing$: Observable<SecurityListing> = combineLatest([this.securityListingIdChanged$, this.securityListing$]).pipe(
    map(([id, listings]) => (id !== -1 ? listings.find(item => item.listingId === id) : null)),
    share(),
  );

  destroy$ = new Subject<void>();

  constructor(
    public store: Store,
    public api: ApiService,
    public dialogRef: MatDialogRef<SecurityListingModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditSecurityListingData,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.securityIdChanged$.next(this.data?.securityId);
    this.store.dispatch(new LoadStockExchanges());
    this.store.dispatch(new LoadCurrencies());
    this.securityListing$.pipe(first(), takeUntil(this.destroy$)).subscribe(data => {
      if (data?.length > 0) {
        this.securityListingIdChanged$.next(data[0].listingId);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * triggering select item change
   *
   * @param evt listing Id
   */
  listingChanged(evt: MatSelectChange): void {
    this.securityListingIdChanged$.next(evt.value);
  }

  /**
   * Submit form values to parent
   *
   * @param data Form values
   */
  async saveSecurityListing(data: FormModel): Promise<void> {
    const { securityId } = this.store.selectSnapshot(SecuritiesState.getSecurityById(this.data.securityId));
    try {
      if (data.listingId !== -1) {
        await lastValueFrom(this.api.editSecurityListings(data.listingId, { ...data, securityId }));
        this.securityIdChanged$.next(this.data.securityId);
        this.store.dispatch(new NotificationShowMessage('success', 'Security listing updated!'));
      } else {
        const newListing = await lastValueFrom(this.api.createSecurityListings({ ...data, securityId }));
        this.securityIdChanged$.next(this.data.securityId);
        this.securityListingIdChanged$.next(newListing.listingId);
        this.store.dispatch(new NotificationShowMessage('success', 'Security listing created!'));
      }
    } catch (e) {
      console.log('Failed to save security listing', data, e);
    }
  }

  /**
   * Display modal to delete security listing
   *
   */
  deleteSecurityListing(currentId: number, listings: SecurityListing[]): void {
    const currentIndex = listings.findIndex(item => item.listingId === currentId);

    let nextIndex = -1;
    if ((listings.length >= 2 && currentIndex > 0) || listings.length === 1) {
      nextIndex = currentIndex - 1;
    } else if (listings.length >= 2 && currentIndex === 0) {
      nextIndex = currentIndex + 1;
    }

    this.dialog
      .open(ConfirmModalComponent, {
        width: '600px',
        data: {
          title: 'Delete Security Listing',
          text: `Do you really want to delete the security listing ${currentId} ?`,
          buttons: [
            { text: 'Cancel', type: 'cancel' },
            { text: 'Delete', type: 'submit', color: 'warn' },
          ],
        },
      })
      .afterClosed()
      .pipe(
        switchMap((type: string) =>
          iif(
            () => type === 'submit',
            defer(() => this.deleteListingId(currentId, listings, nextIndex)),
            EMPTY,
          ),
        ),
      )
      .subscribe();
  }

  /**
   * Delete security listing id
   *
   * @param currentId Current selected listing id
   * @param listings List of listings
   * @param nextIndex Next listing id to select
   */
  async deleteListingId(currentId: number, listings: SecurityListing[], nextIndex: number) {
    try {
      await lastValueFrom(this.api.deleteSecurityListings(currentId));
      this.securityListingIdChanged$.next(listings[nextIndex]?.listingId || -1);
      this.securityIdChanged$.next(this.data.securityId);
      this.store.dispatch(new NotificationShowMessage('success', 'Security listing deleted!'));
    } catch (e) {
      this.store.dispatch(new NotificationShowMessage('error', 'Failed to delete security listing!', e));
    }
  }
}
