import { AgRendererComponent } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

export interface IIconParams extends ICellRendererParams {
  iconClass: string;
  iconColor?: ThemePalette;
}

@Component({
  selector: 'app-table-cell-icon',
  template: ' <mat-icon *ngIf="value" [style.color]="iconColor" [ngClass]="iconClass">{{ value }}</mat-icon> ',
  styleUrls: ['./table-cell-icon.component.scss'],
})
export class TableCellIconComponent implements AgRendererComponent {
  iconClass?: string;

  iconColor?: ThemePalette;

  value: string;

  constructor() {}

  refresh(params: IIconParams): boolean {
    this.iconClass = params.iconClass;
    this.iconColor = params.iconColor;
    this.value = params.valueFormatted;
    return true;
  }

  agInit(params: IIconParams) {
    this.iconClass = params.iconClass;
    this.iconColor = params.iconColor;
    this.value = params.valueFormatted;
  }
}
